/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import Multiselect from 'multiselect-react-dropdown';
import FormSelectField from "../FormComponents/form-select-field";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import { PictureAsPdf, OndemandVideo } from '@material-ui/icons';
import {
    getExerciseList, deleteExercise, handleExerciseStatus, getAllExercises, getImported,
    assignToUser, getAllCategoriesWithSub
} from "../../crud/auth.crud";
import moment from 'moment-timezone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from "clsx";
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@material-ui/core";

class Exercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseData: [],
            exerciseCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 10,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentExercise: null,
            validated: false,
            disabledBtn: false,
            categoryData: [],
            categoryFilter: null,
            allExercise: [],
            allUsers: [],
            userGroup: ""
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        if (this.props && this.props.location && this.props.location.state) {
            console.log(this.props.location)
            this.setState({
                categoryFilter: this.props.location.state.detail
            });
            setTimeout(() => {
                this.getExerciseList();
            }, 500);
        } else {
            this.getExerciseList();
        }
        this.getAllExercises();
        this.getImported();
        this.getAllCategoriesWithSub();
    }

    getAllCategoriesWithSub = async () => {
        const { authToken } = this.props;
        await getAllCategoriesWithSub(authToken).then(result => {
            this.setState({
                categoryData: result.data.payload ? result.data.payload.data.rows : [],
            });
        }).catch(err => {
            console.log(err.response, err.message)
            this.setState({
                categoryData: []
            });
        });
    }

    getAllExercises = async () => {
        const { authToken } = this.props;
        await getAllExercises(authToken).then(result => {
            this.setState({
                allExercise: result.data.payload ? result.data.payload.data.rows : [],
            });
        }).catch(err => {
            console.log(err.response, err.message)
            this.setState({
                allExercise: []
            });
        });
    }

    getImported = async () => {
        const { authToken } = this.props;
        await getImported(authToken).then(result => {
            this.setState({
                allUsers: result.data.payload ? result.data.payload.data.rows : [],
            });
        }).catch(err => {
            console.log(err.response, err.message)
            this.setState({
                allUsers: []
            });
        });
    }

    getExerciseList = async (searchData) => {
        console.log(this.state.categoryFilter)
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        var categoryF = this.state.categoryFilter;
        var status = (this.state.status === 0 || this.state.status === 1) ? this.state.status : null;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null; //searchData !== undefined ? searchData : null;
        await getExerciseList(authToken, search, limitV, sortByV,
            sortOrderV, activePage, startDate, endDate, status, categoryF).then(result => {
                this.setState({
                    exerciseData: result.data.payload ? result.data.payload.data.rows : [],
                    exerciseCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    exerciseData: [],
                    exerciseCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    handleCategoryFilter = (type, value) => {
        if (type === "Category") {
            this.setState({ categoryFilter: value });
        }
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getExerciseList(event.target.value);

        // }
        if (event.target.value.length === 0) {
            this.getExerciseList();
        }
    };

    handleStatus = (value) => {
        this.setState({ status: value });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleModal = (value, currentExercise) => {
        this.setState({ modalType: value, show: true, currentExercise });
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleShow = (e) => {
        this.setState({
            show: true,
        });
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            status: (value === false) ? 0 : 1,
            type: "updateStatus"
        }
        await handleExerciseStatus(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleAddExercise = () => {
        this.props.history.push('/admin/manageExercise');
    }

    handleEditExercise = (item) => {
        this.props.history.push({
            pathname: '/admin/manageExercise',
            state: { detail: item }
        })
    }

    assignToUser = async (values, setSubmitting) => {
        const { authToken } = this.props;

        var exArr = values?.exercise_id.map((item) => { return { exercise_id: item.id } });
        var userArr = values?.user_id.map((item) => { return { id: item.id } });
        let user_groups= values?.user_group
        var postdata = {
            exerciseArr: exArr,
            user_id: userArr,
            user_group:user_groups,
        }

        await assignToUser(authToken, postdata).then(result => {
            setSubmitting(true);
            this.handleClose();
            this.setState({ loading: true });
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            this.setState({ loading: false });
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
    }

    handleDelete = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var data = {
            id: this.state.currentExercise.id
        }
        await deleteExercise(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);

    }

    onChangeTickets = (e, values, setValues) => {
        // update dynamic form
        var exercise_id = e.target.value;

        setValues({ ...values, exercise_id });
    }

    renderDeleteModal = () => {
        const { currentExercise, disabledBtn, modalType, isFocus, allUsers, allExercise,userGroup } = this.state;

        if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete Exercise</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this exercise <b>{currentExercise && currentExercise.title}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDelete(e)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        } else {
            var customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
                : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Assign Exercise To User</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        enableReinitialize
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            this.assignToUser(values, setSubmitting)
                        }}
                        initialValues={{
                            exercise_id: [],
                            user_id: [],
                            user_group: userGroup,
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};

                            if (values.exercise_id.length <= 0) {
                                errors.exercise_id = "Please select any exercise";
                            }
                            if (values.user_group === "selected_user") {
                                if (values.user_id.length <= 0) {
                                    errors.user_id = "Please select any user";
                                }
                            }

                            if (values.user_group === "") {
                                errors.user_group = "Please select any users from above options";
                            }

                            console.log(errors)
                            return errors;
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            isValid,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    {/* <FormSelectField
                                        as={Col}
                                        md="12"
                                        style={customStyle}
                                        controlId="validationcategory"
                                        label="Exercise Name"
                                        type="text"
                                        name="exercise_id"
                                        onChange={e => this.onChangeTickets(e, values, props.setValues)}
                                    >
                                        <option value="">Select Exercise</option>
                                        {allExercise.length > 0 &&
                                            allExercise.map((item, index) => (
                                                <option key={item.id} value={item.id} >{item.title}</option>
                                            ))}
                                    </FormSelectField> */}
                                    <Form.Row className="mx-0 px-3">
                                        <Form.Label style={{ fontWeight: 600 }}>Exercises</Form.Label>
                                    </Form.Row>
                                    <Form.Group as={Col} md="12" className="px-0" controlId="validationcategory">
                                        <div className="col-md-12">
                                            <Multiselect
                                                style={{
                                                    searchBox: {
                                                        border: errors.exercise_id ? '2px solid #dc3545' : '2px solid #E3E3E3',
                                                        borderRadius: '0px',
                                                    },
                                                    chips: {
                                                        marginBottom: '0px'
                                                    }
                                                }}
                                                options={allExercise}
                                                selectedValues={values.exercise_id}
                                                onSelect={(e) => props.setFieldValue("exercise_id", e)}
                                                onRemove={(e) => props.setFieldValue("exercise_id", e)}
                                                displayValue="title"
                                            />
                                            {errors.exercise_id && (<div className="invalid-msg">{errors.exercise_id}</div>)}
                                        </div>
                                    </Form.Group>
                                    <Form.Row className="mx-0 px-3">
                                        <Form.Label style={{ fontWeight: 600 }}>Users</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            //defaultValue=""
                                            name="user_group"
                                            value={values.user_group}
                                            onChange={(e) => { props.setFieldValue("user_group", e.target.value); e.target.value === "all_user" ? props.setFieldValue("user_id", []) : props.setFieldValue("user_id", values.user_id); }}
                                            className="user-group"
                                        >
                                            <FormControlLabel value="all_user" control={<Radio />} label="All User" />
                                            <FormControlLabel value="selected_user" control={<Radio />} label="Selected User" />

                                        </RadioGroup>
                                    </Form.Row>

                                    {errors.user_group && <FormHelperText className="text-danger">{errors.user_group}</FormHelperText>}
                                    {values.user_group === "selected_user" ?
                                        <div>

                                    <Form.Group as={Col} md="12" className="px-0" controlId="validationEmail">
                                        <div className="col-md-12">
                                            <Multiselect
                                                style={{
                                                    searchBox: {
                                                        border: errors.user_id ? '2px solid #dc3545' : '2px solid #E3E3E3',
                                                        borderRadius: '0px',
                                                    },
                                                    chips: {
                                                        marginBottom: '0px'
                                                    }
                                                }}
                                                options={allUsers}
                                                selectedValues={values.user_id}
                                                onSelect={(e) => props.setFieldValue("user_id", e)}
                                                onRemove={(e) => props.setFieldValue("user_id", e)}
                                                displayValue="fullname"
                                            />
                                            {errors.user_id && (<div className="invalid-msg">{errors.user_id}</div>)}
                                        </div>
                                    </Form.Group>
                                    </div>
                                     : "" } 


                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Modal.Footer>


                            </Form>)}
                    </Formik>
                </div >
            );
        }
    }

    render() {
        const { exerciseData, exerciseCount, categoryData, categoryFilter, activePage, limit, searchValue, loading, isFocus, startDate, endDate } = this.state;
        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />

                <div className="d-flex flex-row-reverse mr-5 mt-3">

                    <button
                        // disabled={loading}
                        className='btn btn-info btn-elevate kt-login__btn-info ml-3'
                        onClick={(e) => this.handleModal("AssignUser")}
                    >
                        {/* {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />} */}
                        Assign User
                    </button>
                    <button
                        // disabled={loading}
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={this.handleAddExercise}
                    >
                        Add Exercise
                    </button>


                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic"> 
                                                    Filter By Status</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(1)}>Active</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(0)}>Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown className="col-md-auto category-scroll" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic1" >
                                                    Filter By Category</Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {(categoryData.length > 0) && categoryData.map((item) =>
                                                        <Dropdown.Item key={item.id}
                                                            onClick={() => this.handleCategoryFilter("Category", item.id)}>{item.name}</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* {(categoryFilter && exerciseData.length > 0) && 
                                                <Dropdown className="col-md-2" drop="right">
                                                    <Dropdown.Toggle className="my-2" variant="info" id="dropdown-basic2" >
                                                        Filter By Subcategory</Dropdown.Toggle>

                                                    {(categoryData.length > 0) &&
                                                        categoryData.map((item1) =>
                                                            (item1.id == categoryFilter) &&
                                                            (item1.Subcategories.length > 0) &&
                                                            <Dropdown.Menu>
                                                                {(item1.Subcategories.map((it) =>
                                                                    < Dropdown.Item key={it.id}
                                                                        onClick={() => this.handleCategoryFilter("Subcategory", it.id)}>
                                                                        {it.name}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>)}
                                                </Dropdown>} */}
                                        </div>
                                        <div className="row"><div
                                            className="input-group align-self-center col-md-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                    <i className="flaticon2-search-1" />
                                                </span>
                                            </div>

                                            <input
                                                type="text"
                                                ref={this.inputRef}
                                                placeholder="Search..."
                                                value={searchValue}
                                                onFocus={() => this.changeFocus()}
                                                style={customStyle}
                                                onChange={this.handleSearchChange}
                                                className="form-control kt-quick-search__input"
                                            />
                                            {(searchValue) && (
                                                <div className="input-group-append" onClick={this.clear}>
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="la la-close kt-quick-search__close"
                                                            style={{ display: "flex" }} />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                            <div className="col-md-3 mb-5 mt-3" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={startDate}
                                                        autoComplete="off"
                                                        onChange={(value) => this.handleChange('startDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 mb-5 mr-4 mt-3" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="endDate"
                                                        label="End Date"
                                                        format="MM/dd/yyyy"
                                                        minDate={startDate}
                                                        autoComplete="off"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={endDate}
                                                        onChange={(value) => this.handleChange('endDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                {/* <th><b>id</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                </th> */}
                                                <th><b>Title</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('title', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('title', 'DESC')} />
                                                </th>
                                                <th><b>Category Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('categoryname', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('categoryname', 'DESC')} />
                                                </th>
                                                {/* <th><b>Subcategory Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('sub_categoryname', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('sub_categoryname', 'DESC')} />
                                                </th> */}
                                                <th><b>Points</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'DESC')} />
                                                </th>
                                                <th><b>Duration</b>
                                                </th>
                                                <th><b>Exercise Attachments</b>
                                                </th>
                                                <th><b>Status</b></th>
                                                <th><b>Date</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                </th>
                                                <th><b>Action</b>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {exerciseData.length > 0 ?
                                                exerciseData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.title ? item.title : "-"}</td>
                                                        <td>{item.Category ? item.Category.name : "-"}</td>
                                                        {/* <td>{item.sub_categoryname ? item.sub_categoryname.join(",  ") : "-"}</td> */}
                                                        <td>{item.points ? item.points : "-"}</td>
                                                        <td>{item.duration ? item.duration : "0"} Minutes</td>
                                                        <td className="text-center">
                                                            {item.videoAttached && <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Video Attachment</Tooltip>}>
                                                                <a href={item.videoAttached} target="_blank"
                                                                    className={clsx("kt-menu__link-icon", item.pdfAttached && "pr-4")}
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}><OndemandVideo /></a>
                                                            </OverlayTrigger>}
                                                            {item.pdfAttached && <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">PDF Attachment</Tooltip>}>
                                                                <a href={item.pdfAttached} target="_blank" className="kt-menu__link-icon"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}><PictureAsPdf /></a>
                                                            </OverlayTrigger>}
                                                            {(!item.videoAttached && !item.pdfAttached) && "-"}

                                                        </td>
                                                        <td><Switch
                                                            onChange={(e) => this.handleSwitchChange(e, item)}
                                                            checked={item.status === 0 ? false : true}
                                                        /></td>
                                                        <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td>

                                                        <td className="text-center">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Edit</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleEditExercise(item);
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>

                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>

                {
                    (exerciseCount > limit) &&
                    <div className="d-flex flex-row-reverse">
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={exerciseCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                }
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderDeleteModal()}
                </Modal>
            </div >
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(Exercise));