/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Formik } from "formik";
import { Table, Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import {addStaffUser, deleteStaffUser, updateStaffUser,
    getStaffList, handleProviderstatus
} from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch";

class Staff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staffData: [],
            staffCount: 0,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 10,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentUser: null,
            validated: false,
            disabledBtn: false,
        };
        this.inputRef = createRef();
    }

    componentDidMount = async () => {
        this.getStaffListFunc();
    }

    getStaffListFunc = async (searchData) => {
        const { authToken } = this.props;
        let limitV = this.state.limit;
        let sortByV = this.state.sortBy;
        let sortOrderV = this.state.sortOrder;
        let activePage = this.state.activePage;
        let search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        await getStaffList(authToken, search, limitV, sortByV,
            sortOrderV, activePage).then(result => {
                this.setState({
                    staffData: result.data.payload ? result.data.payload.data.rows : [],
                    staffCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    staffData: [],
                    staffCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        if (event.target.value.length === 0) {
            this.getStaffListFunc();
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleModal = (value, currentUser) => {
        this.setState({ modalType: value, show: true, currentUser });
    }

    

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        let data = {
            id: item.id,
            status: (value === false) ? 0 : 1
        }

        await handleProviderstatus(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            let msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    handleAddUserSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            name: values.fullname.trim(),
            email: values.email.trim(),
        }
        this.setState({ loading: true });
        setSubmitting(true);
        await addStaffUser(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            this.setState({ loading: false });
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            this.setState({ loading: false });
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    handleEditUserSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            id: this.state.currentUser.id,
            name: values.fullname.trim(),
            email: values.email.trim(),
        }

        await updateStaffUser(authToken, data).then(result => {
            this.handleClose();
            this.setState({ loading: false });
            setSubmitting(true);
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            this.setState({ loading: false });
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    

    handleDelete = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
       
        var data = {
            id: this.state.currentUser.id
        }

        await deleteStaffUser(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getStaffListFunc();
        }, 500);
    }

    // ------->
    renderModalBody = () => {
        const { isFocus, modalType, currentUser, disabledBtn, loading  } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };

    const isEdit = modalType === "Edit" ? true : false;
    if (modalType === "Add" || modalType === "Edit") {
        return (
            <div>
                <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                    <Modal.Title style={{ fontWeight: 700 }}>{modalType} User</Modal.Title>
                </Modal.Header>
                <hr style={{ borderWidth: 2 }} />
                <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = "Please provide email address";
                        } else if (
                            !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(values.email.toLowerCase())
                        ) {
                            errors.email = "Please provide valid email address";
                        }

                        if (values.fullname.trim().length <= 0) {
                            errors.fullname = "Please provide valid name"
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        if (isEdit) {
                            this.handleEditUserSubmit(values, setSubmitting);
                        } else {
                            this.handleAddUserSubmit(values, setSubmitting);
                        }

                    }}
                    initialValues={{
                        fullname: isEdit ? currentUser && currentUser.name : '',
                        email: isEdit ? currentUser && currentUser.email : '',
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        isSubmitting
                    }) => (
                        <Form noValidate={true}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body className="pt-0">

                                <Form.Group as={Col} md="12" controlId="validationFullname">
                                    <Form.Label style={{ fontWeight: 600 }}>Full name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="fullname"
                                        onFocus={() => this.changeFocus()}
                                        placeholder="Full name"
                                        style={customStyle}
                                        value={values.fullname}
                                        onChange={handleChange}
                                        isInvalid={!!errors.fullname}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.fullname}
                                    </Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationEmail">
                                    <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={isEdit && currentUser && currentUser.email}
                                        type="text"
                                        name="email"
                                        onFocus={() => this.changeFocus()}
                                        placeholder="Email"
                                        style={customStyle}
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Modal.Body>
                            <hr className="line-style" />
                            <Modal.Footer>
                                <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                    {loading && <i style={{ margin: '0px 5px' }}
                                        className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                    {isEdit ? "Update" : "Add"}
                                </Button>
                            </Modal.Footer>
                        </Form>)}
                </Formik>
            </div>
        );
    }else if (modalType === "Delete") {
        return (
          <div>
            <Modal.Header closeButton style={{ padding: "0px 0px 0px 20px" }}>
              <Modal.Title style={{ fontWeight: 700 }}>Delete User</Modal.Title>
            </Modal.Header>
            <hr style={{ borderWidth: 2 }} />
            <Form noValidate>
              <Modal.Body>
                <Form.Group as={Col} md="12" className={"text-center"}>
                  <Form.Label style={{ fontWeight: 400 }}>
                    Are you sure you want to delete this user {currentUser && currentUser.email && 
                    <>
                    with <b>{currentUser && currentUser.email}</b> </>} ?
                  </Form.Label>
                </Form.Group>
                {currentUser.userCount > 0 && (
                  <Form.Group
                    as={Col}
                    md="12"
                    className={"text-left text-danger"}
                  >
                    <Form.Label style={{ fontWeight: 400 }}>
                      <div className="d-flex">
                        <span className="d-inline-block">
                          <b>Note:-</b>
                        </span>
                        <span className="d-inline-block pl-1">
                          This doctor is a provider to {currentUser.userCount}{" "}
                          {currentUser.userCount == 1 ? "user." : "users."} &nbsp; <br />
                          If you continue deleting the doctor then the user's
                          association to this doctor will be removed.
                        </span>
                      </div>
                    </Form.Label>
                  </Form.Group>
                )}
              </Modal.Body>
              <hr className="line-style" />
              <Modal.Footer>
                <Button
                  className="ml-auto mr-3"
                  variant="info"
                  disabled={disabledBtn}
                  onClick={(e) => this.handleDelete(e)}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          </div>
        );
    } 
}


    render() {
        const { staffData, staffCount, activePage,
            limit, searchValue, isFocus } = this.state;
        const customStyle = isFocus ? styleSearch : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon}  />
                <div className="d-flex flex-row-reverse mr-5 mt-3">
                    <button
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={(e) => this.handleModal("Add")}
                    >
                        Add User
                    </button>


                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <div
                                                className="input-group align-self-center col-md-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="flaticon2-search-1" />
                                                    </span>
                                                </div>

                                                <input
                                                    type="text"
                                                    ref={this.inputRef}
                                                    placeholder="Search..."
                                                    value={searchValue}
                                                    onFocus={() => this.changeFocus()}
                                                    style={customStyle}
                                                    onChange={this.handleSearchChange}
                                                    className="form-control kt-quick-search__input"
                                                />
                                                {(searchValue) && (
                                                    <div className="input-group-append" onClick={this.clear}>
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="la la-close kt-quick-search__close"
                                                                style={{ display: "flex" }} />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                <th><b>Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'DESC')} />
                                                </th>
                                                <th>Email</th>
                                                <th><b>Status</b></th>
                                                <th><b>Action</b></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {staffData.length > 0 ?
                                                staffData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td>{item.name ? item.name : "-"}</td>
                                                        <td>{item.email ? item.email : <span className="text-center d-block">-</span>}</td>
                                                        <td><Switch
                                                            onChange={(e) =>
                                                                this.handleSwitchChange(e, item)}
                                                            checked={item.status === 0 ? false : true}
                                                        /></td>
                                                       <td className="pl-5">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">
                                                                    {(item.status === 0) ?
                                                                        "Staff member is inactive so cannot edit staff member" :
                                                                        "Edit"}</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (item.status === 1) {
                                                                            this.handleModal("Edit", item);
                                                                        }
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            
                                                        </td>

                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>

                    </PortletBody>
                </Portlet>

                <div className="d-flex flex-row-reverse">
                    {(staffCount > limit) &&
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={staffCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />}
                </div>
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default connect(mapStateToProps)(Staff);