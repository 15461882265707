import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DropzoneArea } from 'material-ui-dropzone';
import { uploadLargeVideoInChunks } from '../../crud/auth.crud';

const chunkSize = 400 * 1024;

function UploadLargeVideo(props) {

  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);

  function handleDrop(e) {
    // e.preventDefault();
    if (e.length == 0) {
      console.log(e.length)
      setCurrentChunkIndex(null);
      setLastUploadedFileIndex(null);
      setCurrentFileIndex(null);
      props.handleFilename("");
    }
    console.log(e)
    setFiles([...e]);
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  async function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const { authToken } = props;
    await uploadLargeVideoInChunks(authToken, params.toString(), data)
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          console.log(isLastChunk)
          file.finalFilename = response.data.finalFilename;
          props.handleFilename(file.finalFilename);
          props.handleUploadLoader(false);
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        props.handleUploadLoader(true);
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [files.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  return (
    <>
      <DropzoneArea
        dropzoneText={(props.isEdit && props.exerciseData.videoAttached) ? "Drag and drop a file here for replacing your existing file" : "Drag and drop a file here or click"}
        dropzoneClass={props.status && props.fileName.length <= 0 ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
        dropzoneParagraphClass={props.status && props.fileName.length <= 0 ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
        acceptedFiles={["video/mp4,video/x-m4v,video/*"]}
        filesLimit={1}
        getPreviewIcon={props.getPreviewIcon}
        showAlerts={['error']}
        maxFileSize={209715200}
        // onChange={(e) => handleDrop(e)}
        onChange={(files) => handleDrop(files)}
      />
      {props.status && props.fileName.length <= 0 && (<div className="invalid-msg">{props.status}</div>)}
      <>
        {files.map((file, fileIndex) => {
          let progress = 0;
          if (file.finalFilename) {
            progress = 100;
          } else {
            const uploading = fileIndex === currentFileIndex;
            const chunks = Math.ceil(file.size / chunkSize);
            if (uploading) {
              progress = Math.round(currentChunkIndex / chunks * 100);
            } else {
              progress = 0;
            }
          }
          return (
            <a className="file"
              target={file.finalFilename ? "_blank" : "_self"}
              key={fileIndex}
              href={file.finalFilename ? process.env.REACT_APP_API_BASE_URL + '/media/video/' + file.finalFilename : 'javascript:;'}>
              <div className="name mt-3">{file.name}</div>
              <div className={"progress " + (progress === 100 ? 'done' : '')}
                style={{ width: progress + '%' }}></div>
            </a>
          );
        })}
      </>

    </>
  );
}
const mapStateToProps = ({ auth: { authToken } }) => ({
  authToken
});

export default withRouter(connect(mapStateToProps)(UploadLargeVideo));