/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import {
    getProductList, addProduct, editProduct, deleteProduct, addProductImg, editProductImg
} from "../../crud/auth.crud";
import Switch from "react-switch";
import { DropzoneArea } from 'material-ui-dropzone';
import { PictureAsPdf, OndemandVideo, Photo } from '@material-ui/icons';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productData: [],
            productCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 10,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentProduct: null,
            validated: false,
            disabledBtn: false
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getProductsList();
    }

    getProductsList = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        var status = (this.state.status === 0 || this.state.status === 1) ? this.state.status : null;
        await getProductList(authToken, search, limitV, sortByV,
            sortOrderV, activePage, status).then(result => {
                this.setState({
                    productData: result.data.payload ? result.data.payload.data.rows : [],
                    productCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    productData: [],
                    productCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getProductsList(event.target.value);
        // }
        if (event.target.value.length === 0) {
            this.getProductsList();
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleModal = (value, currentProduct) => {
        this.setState({ modalType: value, show: true, currentProduct });
    }

    handleSubCategoryModal = (value, currentSub) => {
        this.setState({ modalType: value, showSub: true, currentSub });
    }

    handleClose = () => {
        this.setState({ show: false, showSub: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }
    
    preventFloat = (e) => {
        if (e.key === 'e' || e.key === "." || e.key === "+" || e.key === "-") {
            e.preventDefault();
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            name: item.name,
            status: (value === false) ? 0 : 1
        }
        await editProduct(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleEditProductSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        const { currentProduct } = this.state;
        var postdata = { id: currentProduct.id, ...values };
        postdata.name = values.name.trim()

        if (values.thumbnail) {
            const data = new FormData();

            data.append('file', values.thumbnail)

            delete postdata.thumbnail;

            editProduct(authToken, postdata).then(result => {
                this.handleClose();
                this.setState({ loading: false });
                setSubmitting(true);
                if (result.data.success) {
                    var qData = currentProduct.id;
                    editProductImg(authToken, data, qData).then(result => {
                        this.handleClose();
                        toast.success(result.data.message, {
                            className: "green-css"
                        });
                    }).catch(err => console.log(err))
                }

            }).catch(errors => {
                setSubmitting(false);
                this.handleClose();
                var msg = errors.response ? errors.response.data.message : errors.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
        } else {
            await editProduct(authToken, postdata).then(result => {
                this.handleClose();
                this.setState({ loading: false });
                setSubmitting(true);
                toast.success(result.data.message, {
                    className: "green-css"
                });
            }).catch(err => {
                setSubmitting(false);
                console.log(err.response, err.message);
                this.handleClose();
                var msg = err.response ? err.response.data.message : err.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }

        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleAddProductSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var postdata = values;
        postdata.name = values.name.trim();
        this.setState({ loading: true });
        setSubmitting(true);
        if (values.thumbnail) {
            const data = new FormData();

            if (values.thumbnail) {
                data.append('file', values.thumbnail)
            }

            delete postdata.thumbnail;

            await addProduct(authToken, postdata).then(result => {
                setSubmitting(false);
                this.handleClose();
                this.setState({ loading: false });
                if (result.data.success) {
                    var qData = result.data.payload && result.data.payload.product_id
                    addProductImg(authToken, data, qData).then(result => {
                        this.handleClose();
                        toast.success(result.data.message, {
                            className: "green-css"
                        });
                    }).catch(err => console.log(err))
                }

            }).catch(errors => {
                setSubmitting(false);
                this.handleClose();
                var msg = errors.response ? errors.response.data.message : errors.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    handleDelete = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var data = {
            id: this.state.currentProduct.id
        }
        await deleteProduct(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.setState({ disabledBtn: false });
            this.getProductsList();
        }, 500);

    }

    renderModalBody = () => {
        const { isFocus, modalType, currentProduct, disabledBtn } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
        const handlePreviewIcon = (fileObject, classes) => {
            const { type } = fileObject.file

            const iconProps = {
                className: classes.image,
            }

            if (type.startsWith("video/")) return <OndemandVideo {...iconProps} />
            if (type.startsWith("image/")) return <img className={classes.previewImg} role="presentation" src={fileObject.data} />
            if (type === "application/pdf") return <PictureAsPdf {...iconProps} />
        }

        const isEdit = modalType === "Edit" ? true : false;

        if (modalType === "Add" || modalType === "Edit") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} Product</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validate={values => {
                            const errors = {};

                            if (values.name.trim().length <= 0) {
                                errors.name = "Please provide valid product name"
                            }

                            if (!values.points) {
                                errors.points = "Please provide valid points";
                            }

                            return errors;
                        }}
                        enableReinitialize
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            console.log(values)
                            if (!isEdit && !values.thumbnail) {
                                setStatus(
                                    "Please provide thumbnail for this product"
                                );
                                setSubmitting(false);
                            } else {
                                if (isEdit) {
                                    this.handleEditProductSubmit(values, setSubmitting);
                                } else {
                                    this.handleAddProductSubmit(values, setSubmitting);
                                }
                            }
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                            name: isEdit ? currentProduct && currentProduct.name : '',
                            points: isEdit ? currentProduct && currentProduct.points : '',
                            thumbnail: null
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Group as={Col} md="12" controlId="validationFullname">
                                        <Form.Label style={{ fontWeight: 600 }}>Product name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Product name"
                                            style={customStyle}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationEmail">
                                        <Form.Label style={{ fontWeight: 600 }}>Points</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            name="points"
                                            min={1}
                                            onKeyDown={(e) => this.preventFloat(e)}
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Points"
                                            style={customStyle}
                                            value={values.points}
                                            onChange={handleChange}
                                            isInvalid={!!errors.points}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach Thumbnail</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <DropzoneArea
                                            dropzoneText={(isEdit && currentProduct.thumbnail) ?
                                                "Drag and drop a image here for replacing your existing thumbnail" :
                                                "Drag and drop a image here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("thumbnail", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </Form.Row>
                                    {/* {isEdit && (<Form.Row className="mt-3">
                                        <img style={{ height: 100, width: 100, objectFit: "cover" }} role="presentation" src={currentProduct.thumbnail} />
                                    </Form.Row>)} */}
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div >
            );
        } else if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this product <b>{currentProduct && currentProduct.name}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDelete(e)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        }
    }

    handleStatus = (value) => {
        this.setState({ status: value });
        setTimeout(() => {
            this.getProductsList();
        }, 500);
    }

    render() {
        const { productData, productCount, activePage,
            limit, searchValue, loading, isFocus } = this.state;
        const customStyle = isFocus ? styleSearch : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <div className="d-flex flex-row-reverse mr-5 mt-3">
                    <button
                        disabled={loading}
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleModal("Add");
                        }
                        }
                    >
                        {/* {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />} */}
                        Add Products
                    </button>
                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                    Filter By Status</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleStatus(1)}>Active</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(0)}>Inactive</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown><div
                                                className="input-group align-self-center col-md-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="flaticon2-search-1" />
                                                    </span>
                                                </div>

                                                <input
                                                    type="text"
                                                    ref={this.inputRef}
                                                    placeholder="Search..."
                                                    value={searchValue}
                                                    onFocus={() => this.changeFocus()}
                                                    style={customStyle}
                                                    onChange={this.handleSearchChange}
                                                    className="form-control kt-quick-search__input"
                                                />
                                                {(searchValue) && (
                                                    <div className="input-group-append" onClick={this.clear}>
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="la la-close kt-quick-search__close"
                                                                style={{ display: "flex" }} />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                <th><b>Thumbnail</b></th>
                                                <th><b>Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'DESC')} />
                                                </th>
                                                <th><b>Points</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'DESC')} /></th>
                                                <th><b>Status</b></th>
                                                {/* <th><b>Date</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                    </th> */}
                                                <th><b>Action</b>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {productData.length > 0 ?
                                                productData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td><center>{item.thumbnail ? (<a target="_blank" href={item.thumbnail}><img src={item.thumbnail} 
                                                        style={{ height: 50, width: 50 }} /></a>) : "-"}</center></td>
                                                        <td>{item.name ? item.name : "-"}</td>
                                                        <td>{item.points ? item.points : "-"}</td>
                                                        <td><Switch
                                                            onChange={(e) => this.handleSwitchChange(e, item)}
                                                            checked={item.status === 0 ? false : true}
                                                        /></td>
                                                        {/* <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td> */}
                                                        <td className="text-center">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Edit</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Edit", item);
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>

                    </PortletBody>
                </Portlet>



                <div className="d-flex flex-row-reverse">
                    {(productCount > limit) &&
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={productCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />}
                </div>


                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>

            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default connect(mapStateToProps)(Products);