/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import {
    Button,
    Dropdown,
    Form,
    Col
} from "react-bootstrap";
import {
    getUsersExercises, deleteExercise
} from "../../crud/auth.crud";
import moment from 'moment-timezone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class UserExercises extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseData: [],
            exerciseCount: 0,
            startDate: null,
            endDate: null,
            searchValue: "",
            limit: 10,
            sortBy: 'completed_date',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentExercise: null,
            validated: false,
            disabledBtn: false,
            categoryData: [],
            categoryFilter: null,
            subcategoryData: [],
            subcategoryFilter: null,
            role: 0
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getExerciseList();
        // this.handleUserRole()
    }

    getExerciseList = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        console.log(startDate, endDate)
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null; //searchData !== undefined ? searchData : null;
        var role = this.state.role;
        await getUsersExercises(authToken, search, limitV, sortByV,
            sortOrderV, activePage, startDate, endDate, role).then(result => {
                this.setState({
                    exerciseData: result.data.payload ? result.data.payload.data.rows : [],
                    exerciseCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    exerciseData: [],
                    exerciseCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getExerciseList(event.target.value);

        // }
        if (event.target.value.length === 0) {
            this.getExerciseList();
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleUserRole= (value) => {
        console.log("value",value)
        this.setState({ role: value, });
        setTimeout(() => {
            this.getExerciseList();
        }, 500);
    }

    render() {
        const { exerciseData, exerciseCount, categoryData, categoryFilter, activePage, limit, searchValue, isFocus, startDate, endDate, role } = this.state;
        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                    <div className="row">
                                     
                                            <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic" >
                                                    Filter By Role</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(1)}>Users</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(2)}>Employees</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(0)}>All</Dropdown.Item>
                                                    </Dropdown.Menu>
                                            </Dropdown>
                        </div>
                                        
                                        <div className="row"><div
                                            className="input-group align-self-center col-md-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                    <i className="flaticon2-search-1" />
                                                </span>
                                            </div>

                                            <input
                                                type="text"
                                                ref={this.inputRef}
                                                placeholder="Search..."
                                                value={searchValue}
                                                onFocus={() => this.changeFocus()}
                                                style={customStyle}
                                                onChange={this.handleSearchChange}
                                                className="form-control kt-quick-search__input"
                                            />
                                            {(searchValue) && (
                                                <div className="input-group-append" onClick={this.clear}>
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="la la-close kt-quick-search__close"
                                                            style={{ display: "flex" }} />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                            <div className="col-md-3 mb-5" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={startDate}
                                                        autoComplete="off"
                                                        onChange={(value) => this.handleChange('startDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 mb-5 mr-4" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="endDate"
                                                        label="End Date"
                                                        format="MM/dd/yyyy"
                                                        minDate={startDate}
                                                        autoComplete="off"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={endDate}
                                                        onChange={(value) => this.handleChange('endDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={() => this.handleSubmit()}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                <th><b>User Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('fullname', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('fullname', 'DESC')} />
                                                </th>
                                                <th><b>Exercise Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('title', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('title', 'DESC')} />
                                                </th>
                                                <th><b>Earned Points</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'DESC')} />
                                                </th>
                                                <th><b>Date</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('completed_date', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('completed_date', 'DESC')} />
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {exerciseData.length > 0 ?
                                                exerciseData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td>{item.User ? item.User.fullname : "-"}<br/><div style={{fontSize: 12}}>{item.User ? item.User.email : "-"}</div></td>
                                                        <td>{item.Exercise ? item.Exercise.title : "-"}</td>
                                                        <td>{item.points ? item.points : "-"}</td>
                                                        <td>{moment(item.completed_date).format('MM-DD-YYYY hh:mm A')}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>

                {
                    (exerciseCount > limit) &&
                    <div className="d-flex flex-row-reverse">
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={exerciseCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                }
            </div >
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(UserExercises));