export default {
    header: {
      self: {},
      items: [
        {
          title: "Dashboards",
          root: true,
          alignment: "left",
          page: "dashboard",
          translate: "MENU.DASHBOARD"
        },
        {
          title: "Connect Accounts",
          root: true,
          alignment: "left",
          page: "connectAccounts",
          translate: "MENU.CONNECT_ACCOUNTS"
        },
        {
          title: "Influencers",
          root: true,
          alignment: "left",
          page: "influencers",
          translate: "MENU.INFLUENCER"
        }
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: "Dashboard",
          root: true,
          icon: "flaticon2-architecture-and-city",
          page: "dashboard",
          translate: "MENU.DASHBOARD",
          bullet: "dot"
        },
        {
          title: "Connect Accounts",
          root: true,
          icon: "fas fa-project-diagram",
          page: "connectAccounts",
          translate: "MENU.CONNECT_ACCOUNTS",
          bullet: "dot"
        },
        {
          title: "Influencers",
          root: true,
          icon: "flaticon2-user",
          page: "influencers",
          translate: "MENU.INFLUENCER",
          bullet: "dot"
        },
      ]
    }
  };
  