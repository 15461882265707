import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import { Formik } from "formik";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import {
    Button,
    Form,
    Col
} from "react-bootstrap";
import { registerUser, getEmailFromToken } from "../../crud/auth.crud";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';


var pwdValid = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
var space = /\s/;


class RegisterUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }

    componentDidMount = () => {
        this.getEmailFromToken();
    }

    getEmailFromToken = async () => {
        const queryValues = this.props.location && queryString.parse(this.props.location.search);
        if (queryValues && queryValues.token) {
            await getEmailFromToken(queryValues.token).then(result => {
                this.setState({
                    email: result && result.data.payload.data
                })
            }).catch(err => {
                window.location.href = "error";
            });
        }
    }

    render() {
        const queryValues = this.props.location && queryString.parse(this.props.location.search);
        const customStyle = { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" };
        const email = this.state.email;
        
        return (
            <>
                <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                                "/media/error/bg1.jpg"
                            )})`
                        }}
                    ><ToastContainer />
                        <div className="kt-error-v1__container">
                            <h5 className="kt-error-v1__number ml-0 text-center" style={{ fontSize: 50 }}>Registration</h5>
                            <Portlet className="col-md-4 mx-auto box">
                                <PortletBody>
                                    <Formik
                                    enableReinitialize
                                        initialValues={{
                                            email: email ? email : "",
                                            fullname: "",
                                            username: "",
                                            contact_no: "",
                                            password: '',
                                            confirmPassword: ''
                                        }}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validate={values => {
                                            const errors = {};
                                            if (values.password.trim().length <= 0) {
                                                errors.password = "Please provide valid password";
                                            }

                                            if (!pwdValid.test(values.password)
                                            ) {
                                                errors.password = "Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                            } else if (space.test(values.password)
                                            ) {
                                                errors.password = "Password can't contain space"
                                            }

                                            if (values.confirmPassword.trim().length <= 0) {
                                                errors.confirmPassword = "Please provide valid confirm password ";
                                            }
                                            if (
                                                !pwdValid.test(values.confirmPassword)
                                            ) {
                                                errors.confirmPassword = "Confirm Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                            } else if (space.test(values.confirmPassword)
                                            ) {
                                                errors.password = "Confirm Password can't contain space"
                                            } else if (values.password !== values.confirmPassword) {
                                                errors.confirmPassword =
                                                    "Password and Confirm Password didn't match.";
                                            }

                                            if (values.fullname.trim().length <= 0) {
                                                errors.fullname = "Please provide valid fullname"
                                            }
                                            if (values.username.trim().length <= 0) {
                                                errors.username = "Please provide valid username"
                                            }
                                            if (values.contact_no.length <= 0) {
                                                errors.contact_no = "Please provide valid contact number"
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setStatus, setSubmitting }) => {
                                            console.log(queryValues)
                                            setTimeout(() => {
                                                registerUser({ token: queryValues && queryValues.token, ...values }
                                                ).then(result => {
                                                    window.location.href = "thank-you";
                                                }).catch((errors) => {
                                                    console.log('erres', errors)
                                                    // var msg = errors.response ? errors.response.data.message : errors.message;
                                                    setSubmitting(false);
                                                    window.location.href = "error";
                                                });
                                            }, 1000);
                                        }}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            // handleBlur,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                            isSubmitting
                                        }) => (
                                            <Form noValidate={true}
                                                autoComplete="off"
                                                onSubmit={handleSubmit}
                                            >
                                                {values.email &&
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
                                                    <Form.Control
                                                        required
                                                        readOnly
                                                        type="text"
                                                        name="email"
                                                        style={customStyle}
                                                        placeholder="Email"
                                                        value={values.email}
                                                    />

                                                </Form.Group>}
                                                <Form.Group as={Col} md="12" controlId="validationFullname">
                                                    <Form.Label style={{ fontWeight: 600 }}>Full name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="fullname"
                                                        style={customStyle}
                                                        placeholder="Full name"
                                                        value={values.fullname}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.fullname}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.fullname}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationContact">
                                                    <Form.Label style={{ fontWeight: 600 }}>Contact Number</Form.Label>
                                                    <Form.Control
                                                        required
                                                        min={0}
                                                        type="number"
                                                        style={customStyle}
                                                        name="contact_no"
                                                        placeholder="Contact Number"
                                                        value={values.contact_no}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.contact_no}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.contact_no}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationUsername">
                                                    <Form.Label style={{ fontWeight: 600 }}>Username</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="username"
                                                        style={customStyle}
                                                        placeholder="Username"
                                                        value={values.username}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.username}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationPwd">
                                                    <Form.Label style={{ fontWeight: 600 }}>Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        name="password"
                                                        style={customStyle}
                                                        placeholder="Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.password}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group as={Col} md="12" controlId="validationCPwd">
                                                    <Form.Label style={{ fontWeight: 600 }}>Confirm Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        style={customStyle}
                                                        name="confirmPassword"
                                                        placeholder="Confirm Password"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.confirmPassword}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                                </Form.Group>
                                                <div className="text-center">
                                                    <Button type="submit" className="ml-3" variant="info"
                                                        disabled={isSubmitting}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>)}
                                    </Formik>
                                </PortletBody>
                            </Portlet>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default RegisterUser;
