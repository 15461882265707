/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import { unAssignUserExercise, getAssignedExercise } from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { InsertDriveFile } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router-dom";
import moment from 'moment-timezone';

class UserAssignedExercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseData: [],
            exerciseCount: 0,
            exercisesCount: 0,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 25,
            status: false,
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentUser: null,
            validated: false,
            disabledBtn: false,
            user_id: "",
            exerciseId: []
        };
        this.inputRef = createRef();
    }

    componentDidMount = async () => {
        if (this.props && this.props.location && this.props.location.state) {
            this.setState({
                user_id: this.props.location.state.user_id
            });
            setTimeout(() => {
                this.getAssignedExercise();
            }, 500);
        } else {
            this.getAssignedExercise();
        }
    }
    getAssignedExercise = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var user_id = this.state.user_id;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        await getAssignedExercise(authToken, search, limitV, sortByV,
            sortOrderV, activePage, user_id).then(result => {
                this.setState({
                    exerciseData: result.data.payload ? result.data.payload.data.rows : [],
                    exercisesCount: result.data.payload && result.data.payload.data.count,
                });
            }).catch(err => {
                this.setState({
                    exerciseData: [],
                    exerciseCount: 0,
                    exercisesCount:0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getAssignedExercise();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        if (event.target.value.length === 0) {
            this.getAssignedExercise();
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getAssignedExercise();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number, exerciseData: [...this.state.exerciseData], status: false , exerciseCount :0});
        setTimeout(() => {
            this.getAssignedExercise();
        }, 500);
    }

    handleModal = (value, currentUser) => {
        this.setState({ modalType: value, show: true, currentUser });
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleShow = (e) => {
        this.setState({
            show: true,
        });
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getAssignedExercise();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }
    handleBack = () => {
        this.props.history.push('/admin/users');
    }
    handlecheckbox = (e) => {
        let count = this.state.exerciseCount
        let { value, checked } = e.target;
        let exerciseActive = [...this.state.exerciseData]
        if (checked == true) {
            exerciseActive = exerciseActive.map((item, i) => {
                if (item.exercise_id == value) {
                    item['isChecked'] = true
                    item.isChecked == true ? this.setState({ exerciseCount: count + 1 }) : this.setState({ exerciseCount: count - 1 })
                }
                return item
            });
            this.setState({ exerciseData: exerciseActive });
        }
        else {
            exerciseActive = exerciseActive.map((item) => {
                if (item.exercise_id == value) {
                    item['isChecked'] = false
                    item.isChecked == false ? this.setState({ exerciseCount: count - 1 }) : this.setState({ exerciseCount: count + 1 })
                }
                return item
            });
            this.setState({ status: false, exerciseData: exerciseActive });
        }
    }

    handleAllcheckbox = (e) => {
        let count = 0;
        let { value, checked } = e.target;
        var exerciseAll = [...this.state.exerciseData];
        if (checked == true && value == 'all') {
            exerciseAll = exerciseAll.map((item, index) => {
                item['isChecked'] = true;
                return item
            });
            this.setState({ status: true, exerciseData: exerciseAll, exerciseCount: exerciseAll.length });
        }
        else {
            exerciseAll = exerciseAll.map((item) => {
                item['isChecked'] = false;
                item.isChecked == false ? this.setState({ exerciseCount: count - 1 }) : this.setState({ exerciseCount: count + 1 })
                return item
            });
            this.setState({ status: false, exerciseData: exerciseAll, exerciseCount: count });
        }
    }

    handleDelete = async (event, all) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var exArr = this.state.exerciseData
            .map((item) => {
                if (item.isChecked == true) {
                    return item
                }
            });
        let exId = exArr.map((item) => { return item });
        var data

        if (all === 'all') {
            data = {
                exe_id: exId,
            }
        } else {
            data = {
                id: this.state.currentUser,
                exe_id: []
            }
        }
        await unAssignUserExercise(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            //this.getAssignedExercise();
            window.location.reload(true)
        }, 500);

    }

    renderModalBody = () => {
        const { isFocus, modalType, currentUser, disabledBtn } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
        const isEdit = modalType === "Edit" ? true : false;
        const roleType = 2;
        if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton className={"justify-content-center"} style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title  style={{ fontWeight: 700,}}>Unassign Exercise</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to unassign
                                    this exercise ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDelete(e)}>
                                Unassign
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        }
    }

    render() {
        const { exerciseData, exerciseCount, activePage, limit, searchValue, isFocus, status, exercisesCount} = this.state;

        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };
        return (
            <div>
                <div className="px-5 d-flex align-item-center">
                    <button
                        className='btn btn-warning btn-elevate kt-login__btn-warning px-5 my-0'
                        onClick={this.handleBack}
                    >
                        Back
                    </button>
                    {exerciseData.length  > 0 ?
                        <div className=" text-center tab-text users-padding py-0 mx-auto">{exerciseData[0].User ? exerciseData[0].User.fullname : ""}'s Exercises
                            <div className="users-border-botton">
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <div className="d-flex justify-content-center mr-5 mt-3">
                    {exerciseCount > 0 ?
                        <div>
                            <div className="bg-white shadow p-3 min-width-exercise" >
                                {exerciseCount == 1 ?  <><p className="text-center font-weight-bold mt-3">{exerciseCount} <span>Exercise selected</span></p><p className="text-center mb-3"> Do you want to unassign the selected exercise ?</p></> : <><p className="text-center font-weight-bold mt-3">{exerciseCount} <span>Exercises selected</span></p><p className="text-center mb-3"> Do you want to unassign all the selected exercises ?</p></>}
                                <div className="text-right px-2">
                                    <button
                                        className='btn btn-info'
                                        onClick={(e) => this.handleDelete(e, "all")}
                                    >Unassign</button>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <div
                                                className="input-group align-self-center col-md-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="flaticon2-search-1" />
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    ref={this.inputRef}
                                                    placeholder="Search..."
                                                    value={searchValue}
                                                    onFocus={() => this.changeFocus()}
                                                    style={customStyle}
                                                    onChange={this.handleSearchChange}
                                                    className="form-control kt-quick-search__input"
                                                />
                                                {(searchValue) && (
                                                    <div className="input-group-append" onClick={this.clear}>
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="la la-close kt-quick-search__close"
                                                                style={{ display: "flex" }} />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>
                                        </div>
                                    </form>

                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" value={"all"} checked={status} onChange={(e) => this.handleAllcheckbox(e)}
                                                    />&nbsp;  </th>
                                                <th><b>Exercise Name</b></th>
                                                <th><b>Category Name</b></th>
                                                <th><b>Assigned Date</b></th>
                                                <th><b>Action</b>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exerciseData.length > 0 ?
                                                exerciseData.map((item, index) =>
                                                    <tr key={item.exercise_id}>
                                                        <td> <input type="checkbox" value={item.exercise_id}
                                                            checked={item.isChecked} onChange={(e) => this.handlecheckbox(e)}
                                                        /> &nbsp; </td>
                                                        <td>{item.Exercise.title}</td>
                                                        <td>{item.Exercise.Category ? item.Exercise.Category.name : ""}</td>
                                                        <td>{moment(item.latest_createdAt).format('MM-DD-YYYY hh:mm A')}</td>
                                                        <td className="">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Unassign</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
                {(exercisesCount > limit) &&
                    <div className="d-flex flex-row-reverse">
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={exercisesCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}
const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(UserAssignedExercise));