/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    InputGroup,
    Form,
    Col
} from "react-bootstrap";
import { DropzoneArea } from 'material-ui-dropzone';
import Multiselect from 'multiselect-react-dropdown';
import { PictureAsPdf, OndemandVideo } from '@material-ui/icons';
import { getAllCategoriesWithSub, addExercise, editExercise, addExerciseImg, editExerciseImg, handleDeleteImg, deleteExerciseImg } from "../../crud/auth.crud";
import FormSelectField from "../FormComponents/form-select-field";
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { TitleComponent } from "../FormComponents/TitleComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadLargeVideo from "../FormComponents/UploadLargeVideo";
import UploadPdf from "../FormComponents/UploadPdf";

class ExerciseManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exerciseData: null,
            exerciseCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 25,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentUser: null,
            validated: false,
            disabledBtn: false,
            categoryData: [],
            fileName: "",
            pdfFileName: "",
            uploadLoader: false
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        if (this.props && this.props.location && this.props.location.state) {
            this.setState({
                exerciseData: this.props.location.state.detail
            })
        }
        this.getAllCategoriesWithSub();
    }

    getAllCategoriesWithSub = async () => {
        const { authToken } = this.props;
        await getAllCategoriesWithSub(authToken).then(result => {
            this.setState({
                categoryData: result.data.payload ? result.data.payload.data.rows : [],
            });
        }).catch(err => {
            console.log(err.response, err.message)
            this.setState({
                categoryData: []
            });
        });
    }

    handleEditExercise = (values, setSubmitting) => {
        const { authToken } = this.props;
        const { exerciseData, fileName, pdfFileName } = this.state;
        var postdata = { id: exerciseData.id, ...values };

        if (fileName.length > 0 || values.pdf) {
            const data = new FormData();

            if (values.pdf) {
                data.append('file', values.pdf)
            }
            postdata.filename = fileName;
            postdata.pdfFileName = pdfFileName;
            delete postdata.video;
            delete postdata.pdf;

            editExercise(authToken, postdata).then(result => {
                this.setState({ loading: false });
                setSubmitting(true);

                if (result.data.success) {
                    var qData = exerciseData.id
                    editExerciseImg(authToken, data, qData).then(result => {
                        this.setState({ loading: false });
                        toast.success(result.data.message, {
                            className: "green-css"
                        });
                        setTimeout(() => {
                            this.props.history.goBack();
                        }, 5000);
                    }).catch(err => {
                        this.setState({ loading: false });
                        console.log(err)
                    })
                } else {
                    this.setState({ loading: false });
                }

            }).catch(errors => {
                setSubmitting(false);
                var msg = errors.response ? errors.response.data.message : errors.message;
                this.setState({ loading: false });
                toast.error(msg, {
                    className: "red-css"
                });
            });
        } else {
            editExercise(authToken, postdata).then(result => {
                this.setState({ loading: false });
                setSubmitting(true);
                toast.success(result.data.message, {
                    className: "green-css"
                });
                setTimeout(() => {
                    this.props.history.goBack();
                }, 5000);

            }).catch(errors => {
                setSubmitting(false);
                this.setState({ loading: false });
                var msg = errors.response ? errors.response.data.message : errors.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }

        
    }

    handleAddExercise = (values, setSubmitting) => {
        const { authToken } = this.props;
        const { fileName } = this.state;
        const { pdfFileName } = this.state;
        var postdata = values;
        this.setState({ loading: true });
        setSubmitting(true);
        if (values.video || values.pdf) {

            postdata.filename = fileName;
            postdata.pdfFileName = pdfFileName;
            delete postdata.video;
            delete postdata.pdf;

            addExercise(authToken, postdata).then(result => {
                setSubmitting(false);
                this.setState({ loading: false });
                toast.success(result.data.message, {
                    className: "green-css"
                });
                setTimeout(() => {
                    this.props.history.goBack();
                }, 5000);

            }).catch(errors => {
                setSubmitting(false);
                var msg = errors.response ? errors.response.data.message : errors.message;
                this.setState({ loading: false });
                toast.error(msg, {
                    className: "red-css"
                });
            });
        } else {
            addExercise(authToken, postdata).then(result => {
                setSubmitting(false);
                toast.success(result.data.message, {
                    className: "green-css"
                });
                this.setState({ loading: false });
                setTimeout(() => {
                    this.props.history.goBack();
                }, 5000);
            }).catch(errors => {
                setSubmitting(false);
                this.setState({ loading: false });
                var msg = errors.response ? errors.response.data.message : errors.message;
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }
    }

    handleDeleteImg = (e, type, exerciseData) => {
        e.preventDefault();
        const { authToken } = this.props;
        var data = exerciseData;
        var postdata = {
            id: exerciseData.id
        }
        if (type === "video") {
            postdata.videoAttached = exerciseData.videoAttached
            data.videoAttached = null
        }
        if (type === "pdf") {
            postdata.pdfAttached = exerciseData.pdfAttached
            data.pdfAttached = null
        }
        deleteExerciseImg(authToken, postdata).then(result => {
            if (result.data.success) {
                toast.success(result.data.message, {
                    className: "green-css"
                });

                this.setState({
                    exerciseData: data
                });
            }

        }).catch(errors => {
            var msg = errors.response ? errors.response.data.message : errors.message;
            toast.error(msg, {
                className: "red-css"
            });
        });

    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    preventFloat = (e) => {
        if (e.key === 'e' || e.key === "." || e.key === "+" || e.key === "-") {
            e.preventDefault();
        }
    }

    onChangeTickets = (e, values, setValues) => {
        // update dynamic form
        var category_id = e.target.value;
        var subcategory_id = undefined;

        setValues({ ...values, category_id, subcategory_id });
    }

    handleUploadLoader = (value) => {
        this.setState({ uploadLoader: value });
    }

    handleFilename = (value) => {
        console.log("FileName : ", value)
        this.setState({ fileName: value })
    }

    handlePdfFilename = (value) => {
        console.log("FileName : ", value)
        this.setState({ pdfFileName: value })
    }
    render() {
        const { isFocus, categoryData, exerciseData, fileName, pdfFileName, loading, uploadLoader } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
        var hasSub = false;
        var isEdit = (exerciseData) ? true : false;

        var due = exerciseData && exerciseData.duration && exerciseData.duration.split(":");
        const handlePreviewIcon = (fileObject, classes) => {
            const { type } = fileObject.file
            const iconProps = {
                className: classes.image,
            }

            if (type.startsWith("video/")) return <OndemandVideo {...iconProps} />
            if (type === "application/pdf") return <PictureAsPdf {...iconProps} />
        }

        return (
            <div>
                <TitleComponent title={"Block Chiro | Manage Exercise"} icon={this.props.icon} />
                <ToastContainer />

                <Formik
                    enableReinitialize
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        console.log("values", values)
                        values.video = fileName;
                        values.pdf = pdfFileName;
                        if (!values.video && !values.pdf) {
                            console.log("yes");
                            setStatus("Please provide video or pdf for this exercise");
                            setSubmitting(false);
                        } else {
                            if (isEdit) {
                                this.handleEditExercise(values, setSubmitting)
                            } else {

                                this.handleAddExercise(values, setSubmitting)
                            }
                        }

                    }}
                    initialValues={{
                        title: isEdit ? exerciseData.title : '',
                        description: isEdit ? exerciseData.description : '',
                        instruction: isEdit ? exerciseData.instruction : '',
                        points: isEdit ? exerciseData.points : '',
                        due_date: isEdit ? (due[0]) : '',
                        dueDrop: "Minutes",
                        category_id: isEdit ? (exerciseData.category_id) : undefined,
                        // subcategory_id: isEdit ? (exerciseData.sub_category_ids) : [],
                        video: null,
                        pdf: null,
                        isPremium: isEdit ? (exerciseData.isForPremium == 0 ? false : true) : false
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        const errors = {};

                        if (values.title.trim().length <= 0) {
                            errors.title = "Please provide valid title";
                        }

                        if (values.description.trim().length <= 0) {
                            errors.description = "Please provide valid description";
                        }

                        if (!values.points) {
                            errors.points = "Please provide valid points";
                        }

                        if (!values.category_id) {
                            errors.category_id = "Please select any category";
                        }

                        if (!values.due_date) {
                            errors.due_date = "Please provide valid duration";
                        }

                        if (values.instruction.trim().length <= 0) {
                            errors.instruction = "Please provide valid instructions";
                        }

                        return errors;
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        status,
                        isSubmitting,
                        ...props
                    }) => (
                        <Form noValidate={true} className="row m-0"
                            onSubmit={handleSubmit}
                        >
                            <Portlet className="col-md-7 ml-5">
                                <PortletHeader
                                    className="border-none"
                                    toolbar={
                                        <PortletHeaderToolbar className="col-md-12">
                                            <div
                                                className={"w-100 text-center tab-text tab-active"}>
                                                {isEdit ? "Edit Exercise" : "Add Exercise"}</div>
                                        </PortletHeaderToolbar>
                                    }
                                />
                                <PortletBody>

                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="validationtitle">
                                            <Form.Label style={{ fontWeight: 600 }}>Title</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="title"
                                                onFocus={() => this.changeFocus()}
                                                placeholder="Title"
                                                style={customStyle}
                                                value={values.title}
                                                onChange={handleChange}
                                                isInvalid={!!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationDesc">
                                            <Form.Label style={{ fontWeight: 600 }}>Description</Form.Label>
                                            <Form.Control
                                                required
                                                as={"textarea"}
                                                className={!errors.description ? "textarea-border" : "textarea-border-invalid"}
                                                rows={3}
                                                type="text"
                                                name="description"
                                                onFocus={() => this.changeFocus()}
                                                placeholder="Description"
                                                style={customStyle}
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.description}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationInstr">
                                            <Form.Label style={{ fontWeight: 600 }}>Exercise Instruction</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="instruction"
                                                onFocus={() => this.changeFocus()}
                                                placeholder="Exercise instruction"
                                                style={customStyle}
                                                value={values.instruction}
                                                onChange={handleChange}
                                                isInvalid={!!errors.instruction}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.instruction}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="validationEmail">
                                            <Form.Label style={{ fontWeight: 600 }}>Points</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                name="points"
                                                min={1}
                                                onKeyDown={(e) => this.preventFloat(e)}
                                                onFocus={() => this.changeFocus()}
                                                placeholder="Points"
                                                style={customStyle}
                                                value={values.points}
                                                onChange={handleChange}
                                                isInvalid={!!errors.points}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
                                        </Form.Group>


                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="validationEmail">
                                            <Form.Label style={{ fontWeight: 600 }}>Duration</Form.Label>
                                            <InputGroup>
                                                <Form.Control md="1"
                                                    required
                                                    type="number"
                                                    name="due_date"
                                                    min={1}
                                                    onKeyDown={(e) => this.preventFloat(e)}
                                                    onFocus={() => this.changeFocus()}
                                                    placeholder="Duration"
                                                    style={customStyle}
                                                    value={values.due_date}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.due_date}
                                                />
                                                <Form.Control md="1"
                                                    required
                                                    readOnly
                                                    type="text"
                                                    name="dueDrop"
                                                    onFocus={() => this.changeFocus()}
                                                    placeholder="Duration"
                                                    style={customStyle}
                                                    value={values.dueDrop}
                                                    isInvalid={!!errors.due_date}
                                                />


                                                <Form.Control.Feedback type="invalid">{errors.due_date}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <FormSelectField
                                            as={Col}
                                            md="6"
                                            style={customStyle}
                                            controlId="validationcategory"
                                            label="Category Name"
                                            type="text"
                                            name="category_id"
                                            onChange={e => this.onChangeTickets(e, values, props.setValues)}
                                        >
                                            <option value="">Select a Category</option>
                                            {categoryData.length > 0 &&
                                                categoryData.map((item, index) => (
                                                    <option key={item.id} value={item.id} >{item.name}</option>
                                                ))}
                                        </FormSelectField>
                                    </Form.Row>



                                </PortletBody>
                            </Portlet>
                            <Portlet className="col-md-4 ml-5">
                                <PortletHeader
                                    className="border-none"
                                    toolbar={
                                        <PortletHeaderToolbar className="col-md-12">
                                            <div
                                                className={"w-100 text-center tab-text tab-active"}>{isEdit ? "Edit Video / PDF" : "Add Video / PDF"}</div>
                                        </PortletHeaderToolbar>
                                    }
                                />
                                <PortletBody>
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach Video</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        {/* left here */}
                                        <UploadLargeVideo status={status} fileName={fileName} isEdit={isEdit}
                                            exerciseData={exerciseData} handleFilename={this.handleFilename}
                                            getPreviewIcon={handlePreviewIcon} handleUploadLoader={this.handleUploadLoader} />
                                        {/* <DropzoneArea
                                            dropzoneText={(isEdit && exerciseData.videoAttached) ? "Drag and drop a file here for replacing your existing file" : "Drag and drop a file here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={["video/mp4,video/x-m4v,video/*"]}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            maxFileSize={209715200}
                                            onChange={(files) => props.setFieldValue("video", files[0])}
                                        /> */}
                                        {/* {status && fileName.length <= 0 && (<div className="invalid-msg">{status}</div>)} */}
                                    </Form.Row>
                                    {/* {isEdit && (
                                        (exerciseData.videoAttached) &&
                                        <div className="ml-5 my-3">
                                            <label>Previously added video file</label>
                                            <video width="200" controls>
                                                <source src={exerciseData.videoAttached} />
                                            </video>
                                            <button class="close" onClick={(e) => this.handleDeleteImg(e, "video", exerciseData)}
                                                tabindex="0" type="button" aria-label="Delete">
                                                <i className="la la-close"></i></button></div>
                                    )} */}
                                    <Form.Row>
                                        <Form.Label className="col-md-12 mt-5" style={{ fontWeight: 600 }}>Attach PDF</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <UploadPdf status={status} fileName={pdfFileName} isEdit={isEdit}
                                            exerciseData={exerciseData} handleFilename={this.handlePdfFilename}
                                            getPreviewIcon={handlePreviewIcon} handleUploadLoader={this.handleUploadLoader} />
                                        {/* <DropzoneArea
                                            dropzoneText={(isEdit && exerciseData.pdfAttached) ? "Drag and drop a file here for replacing your existing file" : "Drag and drop a file here or click"}
                                            dropzoneClass={status && fileName <= 0 ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status && fileName <= 0 ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={["application/pdf"]}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("pdf", files[0])}
                                        /> */}
                                        {/* {status && fileName.length <= 0 && (<div className="invalid-msg">{status}</div>)} */}

                                    </Form.Row>
                                    {/* {isEdit && (
                                        (exerciseData.pdfAttached) &&
                                        <div className="ml-5 mt-3">
                                            <label className="mb-0">Previouly added pdf file</label>
                                        </div>
                                    )} */}
                                    {/* {isEdit && (
                                        (exerciseData.pdfAttached) &&
                                        <div className="ml-5 mb-3">
                                            <a target="_blank" href={exerciseData.pdfAttached}
                                            ><i className="flaticon2-file pdf-icon" aria-hidden="true"></i></a>
                                            <button class="close" onClick={(e) => this.handleDeleteImg(e, "pdf", exerciseData)}
                                                tabindex="0" type="button" aria-label="Delete">
                                                <i className="la la-close"></i></button>
                                        </div>
                                    )} */}


                                </PortletBody>
                            </Portlet>
                            <Portlet className="ml-5 mr-4">
                                <PortletBody className="py-2">
                                    <div className="text-right">
                                        <Button type="button" className="mr-3" variant="brand"
                                            onClick={() => this.props.history.goBack()}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="info" disabled={(isSubmitting || uploadLoader)}>
                                            {loading && <i style={{ margin: '0px 5px' }}
                                                className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                            {isEdit ? "Update" : "Add"}
                                        </Button>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </Form>)}
                </Formik>

            </div>
        );
    }
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(ExerciseManage));