/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import AdminHomePage from "../pages/home/AdminHomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import { ThankYou } from "../pages/thank-you/ThankYou";
import CreateAccount from "../pages/user-createAccount/CreateAccount";
import RegisterUser from "app/pages/register-user/RegisterUser";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, userLastLocation, isAdmin, urls } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
            urls: urls,
            isAdmin: auth.user && auth.user.role !== 1,
            isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation()
        }),
        shallowEqual
    );

    const Guard = (window.location.href).includes('admin') ? "Admin" : "Web";
    const thankyou = (window.location.href).includes('thank-you') ? true : false;
    const register = (window.location.href).includes('registration') ? true : false;

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>

            <Switch>
                <Route exact path="/" component={AuthPage} />
                {(Guard === 'Web') && (
                    (!thankyou) ? (
                        <Route exact path="/createAccount" component={CreateAccount} />)
                        : (
                            < Route exact path="/thank-you" component={ThankYou} />)
                )}

                {(Guard === 'Web' && register) && (
                    <Route exact path="/registration" component={RegisterUser} />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/admin/logout" component={LogoutPage} />

                {(!isAuthorized) ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <AuthPage />
                ) : (
                    <Layout isAdmin={isAdmin}>
                        <AdminHomePage userLastLocation={userLastLocation} isAdmin={isAdmin} />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider >
    );
});
