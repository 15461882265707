import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { changePassword } from "../../crud/auth.crud";
import { TitleComponent } from "../FormComponents/TitleComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import queryString from 'query-string';
var pwdValid = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
var space = /\s/;

class ChangePassword extends Component {
  state = { isRequested: false, apiRes: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;


    if (isRequested) {
      return <Redirect to="/admin/login" />;
    }

    const queryValues = this.props.location && queryString.parse(this.props.location.search);
    console.log(this.props)
    return (
      <div className="kt-login__body">

        <TitleComponent title={this.props.title} icon={this.props.icon} />
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.CHANGEPASSWORD.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validate={values => {
              const errors = {};



              if (!values.password) {
                errors.password = "Password " + intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!pwdValid.test(values.password)
              ) {
                errors.password = "Password must contain at least 8 characters, one uppercase, one number and one special case character "
              } else if (space.test(values.password)
              ) {
                errors.password = "Password can't contain space"
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm Password " + intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
              if (
                !pwdValid.test(values.confirmPassword)
              ) {
                errors.confirmPassword = "Confirm Password must contain at least 8 characters, one uppercase, one number and one special case character "
              } else if (space.test(values.confirmPassword)
              ) {
                errors.password = "Confirm Password can't contain space"
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword =
                  "Password and Confirm Password didn't match.";
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {

              setTimeout(() => {
                changePassword(
                  queryValues,
                  values.password
                )
                  .then(result => {

                    console.log('result', result)
                    this.setState({ apiRes: true });
                    setSubmitting(false);
                    toast.success(result.data.message, {
                      className: "green-css"
                    });
                    setTimeout(() => {
                      this.setState({ isRequested: true });
                    }, 2000);
                  })
                  .catch((errors) => {
                    console.log('erres', errors.response)
                    var msg = errors.response && errors.response.data.message;
                    setSubmitting(false);
                    toast.error(msg, {
                      className: "red-css"
                    });
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">

                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                  />
                </div>


                <div className="kt-login__actions">

                  <Link to="/admin">
                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                      Back
                    </button>
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className='btn btn-primary btn-elevate kt-login__btn-primary'
                  >
                    Update Password
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ChangePassword)
);
