// import React from "react";
// import { Redirect, Route, Switch } from "react-router-dom";
// import { Page1 } from "./page1";

// export default function ErrorsPage() {
//   return (
//       <Switch>
//         <Redirect from="/thank-you" exact={true} to="/thank-you" />
//         <Route path="/error/error-v1" component={ErrorPage1} />
//         <Route path="/error/error-v2" component={ErrorPage2} />
//         <Route path="/error/error-v3" component={ErrorPage3} />
//         <Route path="/error/error-v4" component={ErrorPage4} />
//         <Route path="/error/error-v5" component={ErrorPage5} />
//         <Route path="/error/error-v6" component={ErrorPage6} />
//       </Switch>
//   );
// }

import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";

export function ThankYou() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/error/bg1.jpg"
            )})`
          }}
        >
          <div className="kt-error-v1__container text-center">
            <h5 className="kt-error-v1__number ml-0 mt-0 " style={{fontSize: 80}}>Thank you !</h5>
            {/* <p className="kt-error-v1__desc">For Accept Invitation</p> */}
          </div>
        </div>
      </div>
    </>
  );
}

