import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Dashboard from "./Dashboard";
import Users from "./Users";
// import UpdateProfile from "./UpdateProfile";
import Category from "./Category";
import Exercise from "./Exercise";
import ExerciseManage from "./ExerciseManage";
import UpdateProfile from "./UpdateProfile";
import Products from "./Products";
import RedeemHistory from "./RedeemHistory";
import UserExercises from "./UserExercises";
import MailConfigure from "./MailConfigure";
import UserAssignedExercise from "./UserAssignedExercise";

import { LayoutSplashScreen } from "../../../_metronic";
import Employee from "./Employee";
import Staff from "./Staff";

export default function AdminHomePage(props) {
  return (
    <Suspense fallback={<LayoutSplashScreen userLastLocation={props.userLastLocation}/>}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/admin/login" to="/admin/users" />
        }
        <Route exact path="/admin/employees" component={() => <Employee icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Employees"} />} />
        <Route exact path="/admin/categories" component={() => <Category icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Category"} />} />
        <Route exact path="/admin/users" component={() => <Users icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Users"} />} />
        <Route exact path="/admin/exercise-library"  component={() => <Exercise icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Exercise"} />} />
        <Route exact path="/admin/products"  component={() => <Products icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Products"} />} />
        <Route exact path="/admin/user-exercise"  component={() => <UserExercises icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | User Exercise"} />} />
        <Route exact path="/admin/redeem-history"  component={() => <RedeemHistory icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Redeem History"} />} />
        <Route exact path="/admin/manageExercise"  component={() => <ExerciseManage icon={process.env.PUBLIC_URL + "block_trans.png"} />} />
        <Route exact path="/admin/update-profile"  component={() => <UpdateProfile icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Update Profile"} />} />
        <Route exact path="/admin/mail-configure"  component={() => <MailConfigure icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Mail Configure"} />} />
        <Route exact path="/admin/assignedExercise"  component={() => <UserAssignedExercise icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | User Exercise Unassign"}/>} />
        <Route exact path="/admin/staff" component={() => <Staff icon={process.env.PUBLIC_URL + "block_trans.png"} title={"Block Chiro | Staff"} />} />
      </Switch>
    </Suspense>
  );
}
