import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from './ChangePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AuthPage() {

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <ToastContainer />
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="kt-grid__item" style={{
                      display: "block",
                      margin: "auto"
                    }}>
                <Link to="/admin" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={require("assets/img/JLouis_Logo_white.png")}
                  />
                </Link>
              </div>
              {/* <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                  <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title"></h3>
                  </div>
                </div> */}

              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2021 apps.jlouis.com
                  </div>
                  {/* <div className="kt-login__menu">
                      <Link to="/terms" className="kt-link">
                        Privacy
                      </Link>
                      <Link to="/terms" className="kt-link">
                        Legal
                      </Link>
                      <Link to="/terms" className="kt-link">
                        Contact
                      </Link>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/admin/login" component={(props) => <Login icon={process.env.PUBLIC_URL + "JLouis_Logo_Black.png"} title={"Login"} {...props}/>} />

                <Route
                  path="/admin/forgot-password"
                  component={(props) => <ForgotPassword icon={process.env.PUBLIC_URL + "JLouis_Logo_Black.png"} title={"Forgot Password"} {...props} />}
                />
                <Route
                  path="/admin/changePassword"
                  component={(props) => <ChangePassword icon={process.env.PUBLIC_URL + "JLouis_Logo_Black.png"} title={"Change Password"} {...props} />}
                />
                <Redirect from="/admin" exact={true} to="/admin/login" />
                <Redirect to="/admin/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
