export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Dashboard",
      //   root: true,
      //   alignment: "left",
      //   page: "/admin/dashboard",
      // },
      {
        title: "Users",
        root: true,
        alignment: "left",
        page: "/admin/users",
      },
      {
        title: "Employees",
        root: true,
        icon: "fas fa-solid fa-users",
        page: "/admin/employees",
        bullet: "dot"
      },
      {
        title: "Staff",
        root: true,
        icon: "fas fa-solid fa-users",
        page: "/admin/staff",
        bullet: "dot"
      },
      {
        title: "Categories",
        root: true,
        alignment: "left",
        page: "/admin/categories",
      },
      {
        title: "Exercise Library",
        root: true,
        alignment: "left",
        page: "/admin/exercise-library",
      },
      {
        title: "User Exercise",
        root: true,
        alignment: "left",
        page: "/admin/user-exercise",
      },
      {
        title: "Product Management",
        root: true,
        alignment: "left",
        page: "/admin/products",
      },
      {
        title: "Redeem History",
        root: true,
        alignment: "left",
        page: "/admin/redeem-history",
      },
      {
        title: "Mail Configure",
        root: true,
        alignment: "left",
        page: "/admin/mail-configure",
      }
    ]
  },
  aside: {
    self: {},
    items: [
      // {
      //   title: "Dashboard",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "dashboard",
      //   bullet: "dot"
      // },
      {
        title: "Users",
        root: true,
        icon: "flaticon2-user",
        alignment: "left",
        page: "users",
      },
      {
        title: "Employees",
        root: true,
        icon: "fas fa-solid fa-users",
        page: "/admin/employees",
        bullet: "dot"
      },
      {
        title: "Staff",
        root: true,
        icon: "fas fa-solid fa-users",
        page: "/admin/staff",
        bullet: "dot"
      },
      {
        title: "Categories",
        root: true,
        icon: "fas fa-calendar-alt",
        alignment: "left",
        page: "categories",
      },
      {
        title: "Exercise Library",
        root: true,
        icon: "fas fa-dumbbell",
        alignment: "left",
        page: "exercise-library",
      },
      {
        title: "User Exercise",
        root: true,
        icon: "fas fa-running",
        alignment: "left",
        page: "user-exercise",
      },
      {
        title: "Product Management",
        root: true,
        icon: "fas fa-project-diagram",
        page: "products",
        bullet: "dot"
      },
      {
        title: "Redeem History",
        root: true,
        icon: "flaticon-line-graph",
        page: "redeem-history",
        bullet: "dot"
      },
      {
        title: "Mail Configure",
        root: true,
        icon: "flaticon-settings",
        page: "mail-configure",
        bullet: "dot"
      }
    ]
  }
};