/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import FormSelectField from "../FormComponents/form-select-field";
import {
    getCategoryList, addCategory, editCategory, deleteCategory, getSubcategories, addSubCategory,
    editSubCategory, deleteSubCategory, addCategoryImg, editCategoryImg, importEmployeeFile
} from "../../crud/auth.crud";
import { InsertDriveFile } from '@material-ui/icons';
import Switch from "react-switch";
import { DropzoneArea } from 'material-ui-dropzone';
import Multiselect from 'multiselect-react-dropdown';
import { PictureAsPdf, OndemandVideo, Photo } from '@material-ui/icons';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import {
    FormHelperText
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: [],
            subCategoryData: [],
            categoryCount: 0,
            subCategoryCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 10,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentCategory: null,
            validated: false,
            disabledBtn: false,
            activeTab: 0,
            type: "",
            showSub: false,
            currentSub: null,
            categoryFilter: null
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getCategoryList();
        // this.getSubcategories();
    }

    getCategoryList = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        var type = (this.state.type === 0 || this.state.type === 1) ? this.state.type : null;
        await getCategoryList(authToken, search, limitV, sortByV,
            sortOrderV, activePage, type).then(result => {
                this.setState({
                    categoryData: result.data.payload ? result.data.payload.data.rows : [],
                    categoryCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    categoryData: [],
                    categoryCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    getSubcategories = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var categoryF = this.state.categoryFilter;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        await getSubcategories(authToken, search, limitV, sortByV,
            sortOrderV, activePage, categoryF).then(result => {
                this.setState({
                    subCategoryData: result.data.payload ? result.data.payload.data.rows : [],
                    subCategoryCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    subCategoryData: [],
                    subCategoryCount: 0
                });
            });
    }

    handleCategoryFilter = (value) => {
        this.setState({ categoryFilter: value });
        setTimeout(() => {
            this.getSubcategories();
        }, 500);
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            if (this.state.activeTab === 0) {
                this.getCategoryList();
            } else {
                this.getSubcategories();
            }
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getCategoryList(event.target.value);
        // }
        if (event.target.value.length === 0) {
            if (this.state.activeTab === 0) {
                this.getCategoryList();
            } else {
                this.getSubcategories();
            }
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            if (this.state.activeTab === 0) {
                this.getCategoryList();
            } else {
                this.getSubcategories();
            }
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            if (this.state.activeTab === 0) {
                this.getCategoryList();
            } else {
                this.getSubcategories();
            }
        }, 500);
    }

    handleModal = (value, currentCategory) => {
        this.setState({ modalType: value, show: true, currentCategory });
    }

    handleSubCategoryModal = (value, currentSub) => {
        this.setState({ modalType: value, showSub: true, currentSub });
    }

    handleClose = () => {
        this.setState({ show: false, showSub: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            if (this.state.activeTab === 0) {
                this.getCategoryList();
            } else {
                this.getSubcategories();
            }
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            status: (value === false) ? 0 : 1,
            type: "updateStatus"
        }
        await editCategory(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getCategoryList();
        }, 500);
    }

    handleSwitchSubCategory = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            status: (value === false) ? 0 : 1
        }
        await editSubCategory(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getSubcategories();
        }, 500);
    }

    handleEditCategorySubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        const { currentCategory } = this.state;
        var postdata = { id: currentCategory.id, ...values };
        postdata.name = values.name.trim()
        this.setState({ loading: true });
        if (values.thumbnail) {
            const data = new FormData();

            data.append('file', values.thumbnail)

            delete postdata.thumbnail;

            editCategory(authToken, postdata).then(result => {
                setSubmitting(false);
                this.setState({ loading: false });
                if (result.data.success) {
                    var qData = currentCategory.id;
                    editCategoryImg(authToken, data, qData).then(result => {
                        this.handleClose();
                        toast.success(result.data.message, {
                            className: "green-css"
                        });
                    }).catch(err => console.log(err))
                }

            }).catch(errors => {
                setSubmitting(false);
                this.handleClose();
                var msg = errors.response ? errors.response.data.message : errors.message;
                this.setState({ loading: false });
                toast.error(msg, {
                    className: "red-css"
                });
            });
        } else {
            await editCategory(authToken, postdata).then(result => {
                setSubmitting(false);
                this.handleClose();
                this.setState({ loading: false });
                setSubmitting(true);
                toast.success(result.data.message, {
                    className: "green-css"
                });
            }).catch(err => {
                setSubmitting(false);
                console.log(err.response, err.message);
                this.handleClose();
                var msg = err.response ? err.response.data.message : err.message;
                this.setState({ loading: false });
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }

        setTimeout(() => {
            this.getCategoryList();
        }, 500);
    }

    handleAddCategorySubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var postdata = values;
        postdata.name = values.name.trim();
        this.setState({ loading: true });
        setSubmitting(true);
        if (values.thumbnail) {
            const data = new FormData();

            if (values.thumbnail) {
                data.append('file', values.thumbnail)
            }

            delete postdata.thumbnail;

            await addCategory(authToken, postdata).then(result => {
                setSubmitting(false);
                this.setState({ loading: false });
                if (result.data.success) {
                    var qData = result.data.payload && result.data.payload.category_id
                    addCategoryImg(authToken, data, qData).then(result => {
                        this.handleClose();
                        toast.success(result.data.message, {
                            className: "green-css"
                        });
                    }).catch(err => console.log(err))
                }

            }).catch(errors => {
                setSubmitting(false);
                this.handleClose();
                var msg = errors.response ? errors.response.data.message : errors.message;
                this.setState({ loading: false });
                toast.error(msg, {
                    className: "red-css"
                });
            });
        }
        setTimeout(() => {
            this.getCategoryList();
        }, 500);
    }

    handleDelete = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var data = {
            id: this.state.currentCategory.id
        }
        await deleteCategory(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.setState({ disabledBtn: false });
            this.getCategoryList();
        }, 500);

    }

    renderModalBody = () => {
        const { isFocus, modalType, currentCategory, disabledBtn, loading } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };
        const handlePreviewIcon = (fileObject, classes) => {
            const { type } = fileObject.file

            const iconProps = {
                className: classes.image,
            }

            if (type.startsWith("video/")) return <OndemandVideo {...iconProps} />
            if (type.startsWith("image/")) return <img className={classes.previewImg} role="presentation" src={fileObject.data} />
            if (type === "application/pdf") return <PictureAsPdf {...iconProps} />
        }

        const isEdit = modalType === "Edit" ? true : false;

        if (modalType === "Add" || modalType === "Edit") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validate={values => {
                            const errors = {};

                            if (values.name.trim().length <= 0) {
                                errors.name = "Please provide valid category name"
                            }

                            if (values.description.trim().length <= 0) {
                                errors.description = "Please provide valid description";
                            }

                            if (values.forEmployees == 0 && values.forFreeUsers == 0 && values.isPremium == 0) {
                                errors.forFreeUsers = "Please select anyone checkbox from above options";
                            }

                            return errors;
                        }}
                        enableReinitialize
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (!isEdit && !values.thumbnail) {
                                setStatus(
                                    "Please provide thumbnail for this category"
                                );
                                setSubmitting(false);
                            } else {
                                if (isEdit) {
                                    this.handleEditCategorySubmit(values, setSubmitting);
                                } else {
                                    this.handleAddCategorySubmit(values, setSubmitting);
                                }
                            }
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                            name: isEdit ? currentCategory && currentCategory.name : '',
                            description: isEdit ? currentCategory && currentCategory.description : '',
                            isPremium: isEdit ? (currentCategory.isPremium == 0 ? false : true) : false,
                            forEmployees: isEdit ? (currentCategory.forEmployees == 0 ? false : true) : false,
                            forFreeUsers: isEdit ? (currentCategory.forFreeUsers == 0 ? false : true) : false,
                            thumbnail: null
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >

                                <Modal.Body className="pt-0">
                                    <Form.Group as={Col} md="12" controlId="validationFullname">
                                        <Form.Label style={{ fontWeight: 600 }}>Category name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Category name"
                                            style={customStyle}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationDesc">
                                        <Form.Label style={{ fontWeight: 600 }}>Description</Form.Label>
                                        <Form.Control
                                            required
                                            as={"textarea"}
                                            className={!errors.description ? "textarea-border" : "textarea-border-invalid"}
                                            rows={3}
                                            type="text"
                                            name="description"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Description"
                                            style={customStyle}
                                            value={values.description}
                                            onChange={handleChange}
                                            isInvalid={!!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach Thumbnail</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <DropzoneArea
                                            dropzoneText={(isEdit && currentCategory.thumbnail) ?
                                                "Drag and drop a image here for replacing your existing thumbnail" :
                                                "Drag and drop a image here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={['image/*']}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("thumbnail", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </Form.Row>
                                    {/* {isEdit && (<Form.Row className="mt-3">
                                        <img style={{ height: 100, width: 100, objectFit: "cover" }} role="presentation" src={currentCategory.thumbnail} />
                                    </Form.Row>)} */}
                                    <Form.Row>
                                        <Form.Check
                                            name="forFreeUsers"
                                            checked={values.forFreeUsers}
                                            label="Allow for free users"
                                            onChange={handleChange}
                                            isInvalid={!!errors.forFreeUsers}
                                            className="pl-3"
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Check
                                            name="isPremium"
                                            checked={values.isPremium}
                                            label="Allow for premium users"
                                            onChange={handleChange}
                                            isInvalid={!!errors.forFreeUsers}
                                            className="pl-3"
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Check
                                            name="forEmployees"
                                            checked={values.forEmployees}
                                            label="Allow for employees"
                                            onChange={handleChange}
                                            isInvalid={!!errors.forFreeUsers}
                                            className="pl-3"
                                        />
                                    </Form.Row>
                                    {errors.forFreeUsers && <FormHelperText className="text-danger">{errors.forFreeUsers}</FormHelperText>}
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        {loading && <i style={{ margin: '0px 5px' }}
                                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div >
            );
        } else if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this category <b>{currentCategory && currentCategory.name}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDelete(e)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        }
        else if (modalType === "Import") {
            const handlePreviewIcon = (fileObject, classes) => {
                const { type } = fileObject.file

                const iconProps = {
                    className: classes.image,
                }

                return <InsertDriveFile {...iconProps} />
            }
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Import Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        onSubmit={async (values, { setStatus, setSubmitting }) => {
                            const { authToken } = this.props;
                            this.setState({ loading: true });
                            if (values.importFile) {
                                const data = new FormData();
                                data.append('importFile', values.importFile)

                                importEmployeeFile(authToken, data).then(result => {

                                    this.handleClose();
                                    this.setState({ loading: false });
                                    toast.success(result.data.message, {
                                        className: "green-css"
                                    });
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 5000);

                                }).catch(errors => {
                                    setSubmitting(false);
                                    this.handleClose();
                                    var msg = errors.response ? errors.response.data.message : errors.message;
                                    this.setState({ loading: false });
                                    toast.error(msg, {
                                        className: "red-css"
                                    });
                                })
                            } else {
                                setStatus(
                                    "Please provide file."
                                );
                                setSubmitting(false);
                            }
                        }}
                        initialValues={{
                            importFile: null
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach File</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <DropzoneArea
                                            dropzoneText={"Drag and drop a file here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={[".csv, text/csv"]}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("importFile", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </Form.Row>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        {loading && <i style={{ margin: '0px 5px' }}
                                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                        Import Category
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}</Formik>

                </div >
            );
        }
    }

    handleEditSubCategorySubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            id: this.state.currentSub.id,
            name: values.name.trim(),
            category_id: values.categoryname
        }

        await editSubCategory(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getSubcategories();
        }, 500);
    }

    handleAddSubCategorySubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            name: values.name.trim(),
            category_id: values.categoryname
        }
        await addSubCategory(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getSubcategories();
        }, 500);
    }

    handleDeleteSub = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var data = {
            id: this.state.currentSub.id
        }
        await deleteSubCategory(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.setState({ disabledBtn: true });
            this.getSubcategories();
        }, 500);

    }

    renderSubModalBody = () => {
        const { isFocus, modalType, currentSub, disabledBtn, categoryData } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };

        const isEdit = modalType === "Edit" ? true : false;
        if (modalType === "Add" || modalType === "Edit") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} Sub Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validate={values => {
                            const errors = {};

                            if (values.name.trim().length <= 0) {
                                errors.name = "Please provide valid subcategory name"
                            }

                            if (values.categoryname === undefined || values.categoryname === "") {
                                errors.categoryname = "Please select category"
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (isEdit) {
                                this.handleEditSubCategorySubmit(values, setSubmitting);
                            } else {
                                this.handleAddSubCategorySubmit(values, setSubmitting);
                            }

                        }}
                        initialValues={{
                            categoryname: isEdit ? currentSub && currentSub.Category.id : undefined,
                            name: isEdit ? currentSub && currentSub.name : ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isSubmitting
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">

                                    {/* <Form.Row> */}
                                    <FormSelectField
                                        as={Col}
                                        md="12"
                                        style={customStyle}
                                        controlId="validationFormik03"
                                        label="Category Name"
                                        type="text"
                                        name="categoryname"
                                    >
                                        <option value="">Select a Category</option>
                                        {categoryData.length > 0 &&
                                            categoryData.map((item, index) => (
                                                <option key={item.id} value={item.id} >{item.name}</option>
                                            ))}
                                    </FormSelectField>

                                    {/* </Form.Row> */}

                                    <Form.Group as={Col} md="12" controlId="validationFullname">
                                        <Form.Label style={{ fontWeight: 600 }}>Subcategory name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Subcategory name"
                                            style={customStyle}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        {isEdit ? "Edit" : "Add"}
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div>
            );
        } else if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete Sub Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this sub category <b>{currentSub && currentSub.name}</b> from Category <b>
                                        {currentSub && currentSub.Category.name}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDeleteSub(e)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        } else if (modalType === "Import") {
            const handlePreviewIcon = (fileObject, classes) => {
                const { type } = fileObject.file

                const iconProps = {
                    className: classes.image,
                }

                return <InsertDriveFile {...iconProps} />
            }
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Import Category</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        onSubmit={async (values, { setStatus, setSubmitting }) => {
                            const { authToken } = this.props;
                            if (values.importFile) {
                                const data = new FormData();
                                data.append('importFile', values.importFile)

                                importEmployeeFile(authToken, data).then(result => {

                                    this.handleClose();
                                    toast.success(result.data.message, {
                                        className: "green-css"
                                    });
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 5000);

                                }).catch(errors => {
                                    setSubmitting(false);
                                    this.handleClose();
                                    var msg = errors.response ? errors.response.data.message : errors.message;
                                    toast.error(msg, {
                                        className: "red-css"
                                    });
                                })
                            } else {
                                setStatus(
                                    "Please provide file."
                                );
                                setSubmitting(false);
                            }
                        }}
                        initialValues={{
                            importFile: null
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach File</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <DropzoneArea
                                            dropzoneText={"Drag and drop a file here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={[".csv, text/csv,application/vnd.ms-excel,"]}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("importFile", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </Form.Row>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        Import Category
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}</Formik>

                </div >
            );
        }
    }

    handleActiveTab = (value) => {
        this.setState({
            activeTab: value,
            searchValue: "",
            activePage: 1
        });
        setTimeout(() => {
            if (value === 0) {
                this.getCategoryList();
            } else {
                this.getCategoryList();
                this.getSubcategories();
            }
        }, 500);
    }

    handleEyeIcon = (categoryId) => {
        this.props.history.push({
            pathname: '/admin/exercise-library',
            state: { detail: categoryId }
        })
    }

    handleStatus = (value) => {
        this.setState({ type: value });
        setTimeout(() => {
            this.getCategoryList();
        }, 500);
    }

    render() {
        const { categoryData, categoryCount, activePage,
            activeTab, limit, searchValue, loading, isFocus, subCategoryCount, subCategoryData } = this.state;
        const customStyle = isFocus ? styleSearch : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <div className="d-flex flex-row-reverse mr-5 mt-3">
                    <button
                        //disabled={loading}
                        className='btn btn-info btn-elevate kt-login__btn-warning ml-3'
                        onClick={(e) => this.handleModal("Import")}
                    >
                        {/* {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />} */}
                        Import Category
                    </button>
                    <button
                        //disabled={loading}
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={(e) => {
                            e.preventDefault();
                            if (activeTab === 0) {
                                this.handleModal("Add");
                            } else {
                                if (categoryData.length > 0) {
                                    this.handleSubCategoryModal("Add");
                                } else {
                                    toast.error("Please add category first", { className: "red-css" })
                                }
                            }
                        }
                        }
                    >
                        {/* {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />} */}
                        {(activeTab === 0) ? "Add Category" : "Add Sub Category"}
                    </button>
                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        {(activeTab === 0) ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="kt-section">
                                        <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                            <div className="row">
                                                <Dropdown className="col-md-auto" drop="down">
                                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                        Filter By Type</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => this.handleStatus(0)}>Free</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.handleStatus(1)}>Premium</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown><div
                                                    className="input-group align-self-center col-md-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="flaticon2-search-1" />
                                                        </span>
                                                    </div>

                                                    <input
                                                        type="text"
                                                        ref={this.inputRef}
                                                        placeholder="Search..."
                                                        value={searchValue}
                                                        onFocus={() => this.changeFocus()}
                                                        style={customStyle}
                                                        onChange={this.handleSearchChange}
                                                        className="form-control kt-quick-search__input"
                                                    />
                                                    {(searchValue) && (
                                                        <div className="input-group-append" onClick={this.clear}>
                                                            <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                                <i className="la la-close kt-quick-search__close"
                                                                    style={{ display: "flex" }} />
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>
                                                {/* <Dropdown className="col-md-2" drop="down">
                                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                        Filter By Employee Type</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => this.handleStatus(0)}>No</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.handleStatus(1)}>Yes</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                                <div className="mr-3 mt-1 ml-auto">
                                                    <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                        onClick={this.handleSubmit}>
                                                        Search
                                                    </button>
                                                </div>
                                                <div className="mr-3 mt-1">
                                                    <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                        onClick={this.handleReset}>
                                                        Reset</button>
                                                </div>

                                            </div>
                                        </form>
                                        <Table striped responsive bordered hover className="table-list-header">
                                            <thead>
                                                <tr>
                                                    {/* <th><b>id</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                    </th> */}
                                                    <th><b>Thumbnail</b></th>
                                                    <th><b>Name</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'DESC')} />
                                                    </th>
                                                    <th><b>Total Exercises</b></th>
                                                    <th><b>Type</b></th>
                                                    <th><b>For Employees Type</b></th>
                                                    <th><b>Status</b></th>
                                                    {/* <th><b>Date</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                    </th> */}
                                                    <th><b>Action</b>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {categoryData.length > 0 ?
                                                    categoryData.map((item, index) =>
                                                        <tr key={item.id}>
                                                            {/* <td>{item.id}</td> */}
                                                            <td><center>{item.thumbnail ? (<a target="_blank" href={item.thumbnail}><img src={item.thumbnail}
                                                                style={{ height: 50, width: 50 }} /></a>) : "-"}</center></td>
                                                            <td>{item.name ? item.name : "-"}</td>
                                                            <td><a onClick={(e) => {
                                                                e.preventDefault();
                                                                if (item.exerciseCount > 0) {
                                                                    this.handleEyeIcon(item.id);
                                                                }
                                                            }}>
                                                                {item.exerciseCount ? item.exerciseCount : 0}
                                                            </a></td>
                                                            <td>{item.isPremium == 1 ? "Premium" : item.forFreeUsers == 1 ? "Free" : "-"}</td>
                                                            <td>{item.forEmployees == 0 ? "No" : "Yes"}</td>
                                                            <td><Switch
                                                                onChange={(e) => this.handleSwitchChange(e, item)}
                                                                checked={item.status === 0 ? false : true}
                                                            /></td>
                                                            {/* <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td> */}
                                                            <td className="text-center">
                                                                {/* <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip id="documentations-tooltip">{(item.Subcategories.length > 0) ?
                                                                        "View Sub Categories" : "No subcategory exist"}</Tooltip>}>
                                                                    <a className="kt-menu__link-icon flaticon-eye pr-4"
                                                                        style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (item.Subcategories.length > 0) {
                                                                                this.handleEyeIcon(item.id);
                                                                            }
                                                                        }}>{""}</a>
                                                                </OverlayTrigger> */}
                                                                <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip id="documentations-tooltip">Edit</Tooltip>}>
                                                                    <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                        style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleModal("Edit", item);
                                                                        }}>{""}</a>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip id="documentations-tooltip">{(item.exerciseCount > 0) ?
                                                                        "You cannot delete category as having one or more exercises attached" :
                                                                        "Delete"}</Tooltip>}>
                                                                    <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                        style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                        onClick={(e) => {
                                                                            if (item.exerciseCount <= 0) {
                                                                                e.preventDefault();
                                                                                this.handleModal("Delete", item)
                                                                            }
                                                                        }}>{""}</a>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan="12" className="text-center">
                                                            No records found!
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </div>
                            : <div>
                                {/* <div className="col-md-12">
                                    <div className="kt-section">
                                        <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                            <div className="row">
                                                <div
                                                    className="input-group align-self-center col-md-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="flaticon2-search-1" />
                                                        </span>
                                                    </div>

                                                    <input
                                                        type="text"
                                                        ref={this.inputRef}
                                                        placeholder="Search..."
                                                        value={searchValue}
                                                        onFocus={() => this.changeFocus()}
                                                        style={customStyle}
                                                        onChange={this.handleSearchChange}
                                                        className="form-control kt-quick-search__input"
                                                    />
                                                    {(searchValue) && (
                                                        <div className="input-group-append" onClick={this.clear}>
                                                            <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                                <i className="la la-close kt-quick-search__close"
                                                                    style={{ display: "flex" }} />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <Dropdown className="col-md-2" drop="right">
                                                    <Dropdown.Toggle className="my-2" variant="info" id="dropdown-basic" >
                                                        Show entries</Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {(categoryData.length > 0) && categoryData.map((item) =>
                                                            <Dropdown.Item key={item.id}
                                                                onClick={() => this.handleCategoryFilter(item.id)}>{item.name}</Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="mr-3 mt-1 ml-auto">
                                                    <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                        onClick={this.handleSubmit}>
                                                        Search
                                                    </button>
                                                </div>
                                                <div className="mr-3 mt-1">
                                                    <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                        onClick={this.handleReset}>
                                                        Reset</button>
                                                </div>

                                            </div>
                                        </form>
                                        <Table striped responsive bordered hover className="table-list-header">
                                            <thead>
                                                <tr>
                                                    <th><b>id</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                    </th>
                                                    <th><b>Sub Category Name</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'DESC')} />
                                                    </th>
                                                    <th><b>Category Name</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('categoryname', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('categoryname', 'DESC')} />
                                                    </th>
                                                    <th><b>Status</b></th>
                                                    <th><b>Date</b>
                                                        <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                        <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                    </th>
                                                    <th><b>Action</b>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {subCategoryData.length > 0 ?
                                                    subCategoryData.map((item, index) =>
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.name ? item.name : "-"}</td>
                                                            <td>{item.Category ? item.Category.name : "-"}</td>
                                                            <td><Switch
                                                                onChange={(e) => this.handleSwitchSubCategory(e, item)}
                                                                checked={item.status === 0 ? false : true}
                                                            /></td>
                                                            <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td>
                                                            <td className="text-center">
                                                                <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip id="documentations-tooltip">Edit</Tooltip>}>
                                                                    <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                        style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleSubCategoryModal("Edit", item);
                                                                        }}>{""}</a>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                    <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                        style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleSubCategoryModal("Delete", item)
                                                                        }}>{""}</a>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan="12" className="text-center">
                                                            No records found!
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>

                                    </div>
                                </div> */}
                            </div>}
                    </PortletBody>
                </Portlet>


                {(activeTab === 0) ?
                    <div className="d-flex flex-row-reverse">
                        {(categoryCount > limit) &&
                            <Pagination
                                bsSize={'medium'}
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={categoryCount}
                                pageRangeDisplayed={limit}
                                onChange={this.handleSelect}
                                itemClass="page-item"
                                linkClass="page-link"
                            />}
                    </div>

                    : <div className="d-flex flex-row-reverse">
                        {(subCategoryCount > limit) &&
                            <Pagination
                                bsSize={'medium'}
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={subCategoryCount}
                                pageRangeDisplayed={limit}
                                onChange={this.handleSelect}
                                itemClass="page-item"
                                linkClass="page-link"
                            />}
                    </div>}
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
                <Modal centered size="md" show={this.state.showSub} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderSubModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(Category));