/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import { getOfficeEmails, addOfficeEmails, updateOfficeEmails } from "../../crud/auth.crud";
import moment from 'moment-timezone';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class MailConfigure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailData: [],
            emailCount: 0,
            loading: false,
            searchValue: "",
            limit: 25,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentEmail: null,
            validated: false,
            disabledBtn: false
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getOfficeEmails();
    }

    getOfficeEmails = async (searchData) => {

        const { authToken } = this.props;

        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var status = (this.state.status === 0 || this.state.status === 1) ? this.state.status : null;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;

        await getOfficeEmails(authToken, search, limitV, sortByV,
            sortOrderV, activePage, status).then(result => {
                this.setState({
                    emailData: result.data.payload ? result.data.payload.data.rows : [],
                    emailCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    emailData: [],
                    emailCount: 0
                });
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        if (event.target.value.length === 0) {
            this.getOfficeEmails();
        }
    };

    handleStatus = (value) => {
        this.setState({ status: value });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleModal = (value, currentEmail) => {
        this.setState({ modalType: value, show: true, currentEmail });
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false, loading: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleShow = (e) => {
        this.setState({
            show: true,
        });
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            status: (value === false) ? 0 : 1,
            email: item.email
        }
        await updateOfficeEmails(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleDeleteEmail = async () => {
        const { authToken } = this.props;
        var data = {
            id: this.state.currentEmail.id,
            type: "isDelete"
        }

        await updateOfficeEmails(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleEditEmailSubmit = async (key, values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            id: this.state.currentEmail.id,
            email: values.email.trim()
        }

        await updateOfficeEmails(authToken, data).then(result => {
            this.handleClose();
            setSubmitting(true);
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            setSubmitting(false);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    handleAddEmailSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            email: values.email.trim()
        }
        this.setState({ loading: true });
        setSubmitting(true);
        await addOfficeEmails(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getOfficeEmails();
        }, 500);
    }

    renderModalBody = () => {
        const { isFocus, modalType, currentEmail, disabledBtn, loading } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };

        const isEdit = modalType === "Edit" ? true : false;
        if (modalType === "Add" || modalType === "Edit") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} User</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};

                            if (!values.email) {
                                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                errors.email = "Please provide email address"
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = "Please provide valid email address"
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (isEdit) {
                                this.handleEditEmailSubmit("edit", values, setSubmitting);
                            } else {
                                this.handleAddEmailSubmit(values, setSubmitting);
                            }

                        }}
                        initialValues={{
                            email: isEdit ? currentEmail && currentEmail.email : ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isSubmitting
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="email"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Email"
                                            style={customStyle}
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        {loading && <i style={{ margin: '0px 5px' }}
                                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div>
            );
        } else if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete User</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this user with <b>{currentEmail && currentEmail.email}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDeleteEmail("delete")}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        }
    }

    render() {
        const { emailData, emailCount, activePage, limit, searchValue, loading, isFocus } = this.state;
        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />

                <div className="d-flex flex-row-reverse mr-5 mt-3">
                    <button
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={(e) => this.handleModal("Add")}
                    >
                        Add Email
                    </button>


                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                    Status</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleStatus(1)}>Active</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(0)}>Inactive</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                        <div className="row"><div
                                            className="input-group align-self-center col-md-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                    <i className="flaticon2-search-1" />
                                                </span>
                                            </div>

                                            <input
                                                type="text"
                                                ref={this.inputRef}
                                                placeholder="Search..."
                                                value={searchValue}
                                                onFocus={() => this.changeFocus()}
                                                style={customStyle}
                                                onChange={this.handleSearchChange}
                                                className="form-control kt-quick-search__input"
                                            />
                                            {(searchValue) && (
                                                <div className="input-group-append" onClick={this.clear}>
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="la la-close kt-quick-search__close"
                                                            style={{ display: "flex" }} />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                <th><b>index</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                </th>
                                                <th><b>Email</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('email', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('email', 'DESC')} />
                                                </th>
                                                <th><b>Status</b></th>
                                                <th><b>Date</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                </th>
                                                <th><b>Action</b>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {emailData.length > 0 ?
                                                emailData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.email}</td>
                                                        <td><Switch
                                                            onChange={(e) =>
                                                                this.handleSwitchChange(e, item)}
                                                            checked={item.status === 0 ? false : true}
                                                        /></td>
                                                        <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td>
                                                        <td className="text-center">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">
                                                                    {"Edit"}</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Edit", item);
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>

                {(emailCount > limit) &&
                    <div className="d-flex flex-row-reverse">
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={emailCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default connect(mapStateToProps)(MailConfigure);