import React from "react";
import { connect } from "react-redux";
import {
    getUserByToken, editProfile, resetPwdAdmin
} from "../../crud/auth.crud";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Form,
    Col,
    InputGroup
} from "react-bootstrap";
import { TitleComponent } from "../FormComponents/TitleComponent";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions } from "../../store/ducks/auth.duck";
import "../../../_metronic/_assets/sass/pages/pricing/pricing-3.scss";
var pwdValid = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
var space = /\s/;

class UpdateProfile extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            activeTab: 0,
            isFocus: false
        };
    }

    updateUser = async () => {
        const { authToken } = this.props;
        await getUserByToken(authToken).then(result => {
            if (result.data.success) {
                var userD = result.data && result.data.payload;
                this.props.requestUser(userD);
            }
        }).catch(err => {
            console.log(err.response, err.message)
        });
    }

    handleSubmit = async (values, setSubmitting) => {

        const { authToken } = this.props;

        const user = {
            ...values
        }
        await editProfile(authToken, user).then(result => {
            setSubmitting(false);
            if (result.data.success) {
                toast.success(result.data.message, {
                    className: 'green-css'
                });
            } else {
                toast.error(result.data.message, {
                    className: 'red-css'
                });
            }
        }).catch(err => {
            setSubmitting(false);
            toast.error("Not able to update profile.", {
                className: 'red-css'
            });
        });

        setTimeout(() => {
            this.updateUser();
        }, 1000);
    }

    handlePassword = async (values, setSubmitting) => {

        const { authToken } = this.props;
        const user = {
            email: values.email,
            password: values.password
        }

        await resetPwdAdmin(authToken, user).then(result => {
            setSubmitting(false);
            if (result.data.success) {
                toast.success(result.data.message, {
                    className: 'green-css'
                });
            } else {
                toast.error(result.data.message, {
                    className: 'red-css'
                });
            }
        }).catch(err => {
            setSubmitting(false);

            toast.error("Not able to change your password.", {
                className: 'red-css'
            });
        });
        setTimeout(() => {
            window.location.reload();
        }, 5000);

    }

    handleActiveTab = (value) => {
        this.setState({
            activeTab: value
        })
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    render() {
        const { isFocus, activeTab } = this.state;
        const { user } = this.props;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 1, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 1, backgroundColor: 'transparent', color: 'black' };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletHeader
                        toolbar={
                            <PortletHeaderToolbar className="col-md-12">
                                <div className={(activeTab === 0) ? "col-md-6 text-center tab-text tab-active" :
                                    "col-md-6 text-center tab-text"} onClick={() => this.handleActiveTab(0)}>Profile</div>
                                <div className={(activeTab === 1) ?
                                    "col-md-6 text-center tab-text tab-active" :
                                    "col-md-6 text-center tab-text"} onClick={() => this.handleActiveTab(1)}>Change Password</div>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        {(activeTab === 0) ?
                            <div className={"tab-pane show px-7 active"} id="kt_user_edit_tab_1" role="tabpanel">

                                <div className="row">
                                    <div className="col-xl-2"></div>
                                    <div className="col-xl-7 my-2">
                                        <div className="row">
                                            <label className="col-3"></label>
                                            <div className="col-9">
                                                <h6 className="text-dark font-weight-bold mb-5">My Information</h6>
                                            </div>
                                        </div>
                                        <Formik
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            validate={values => {
                                                const errors = {};

                                                if (values.fullname.trim().length <= 0) {
                                                    errors.fullname = "Please provide valid fullname"
                                                }
                                                if (values.username.trim().length <= 0) {
                                                    errors.username = "Please provide valid username"
                                                }
                                                if (!values.contact_no) {
                                                    errors.contact_no = "Please provide valid contact number"
                                                }
                                                if (values.contact_no != null && values.contact_no.toString().length <= 9) {
                                                    errors.contact_no = "Please provide valid contact number"
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                                this.handleSubmit(values, setSubmitting);
                                            }}
                                            initialValues={{
                                                fullname: user ? user.fullname : '',
                                                email: user ? user.email : '',
                                                username: user ? user.username : '',
                                                "contact_no": user.contact_no || ''
                                            }}>
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                errors,
                                                isSubmitting
                                            }) => (
                                                <Form noValidate={true}
                                                    onSubmit={handleSubmit}>
                                                    <Form.Row>
                                                        <Form.Label className="col-form-label col-3 text-lg-right text-left">Email</Form.Label>
                                                        <Form.Group as={Col} md="9" controlId="validationEmail">
                                                            <InputGroup className="mb-0">
                                                                <InputGroup.Prepend className="mb-0 prepend-text">
                                                                    <InputGroup.Text style={customStyle}><i className="la la-at" style={{ color: "#3699ff" }}></i></InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control
                                                                    readOnly
                                                                    type="text"
                                                                    name="email"
                                                                    onFocus={() => this.changeFocus()}
                                                                    placeholder="Email"
                                                                    style={customStyle}
                                                                    value={values.email}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Label className="col-form-label col-3 text-lg-right text-left">Full name</Form.Label>
                                                        <Form.Group as={Col} md="9" controlId="validationUFullname">
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="fullname"
                                                                onFocus={() => this.changeFocus()}
                                                                placeholder="Full name"
                                                                style={customStyle}
                                                                value={values.fullname}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.fullname}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.fullname}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Label className="col-form-label col-3 text-lg-right text-left">Username</Form.Label>
                                                        <Form.Group as={Col} md="9" controlId="validationUUsername">
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="username"
                                                                onFocus={() => this.changeFocus()}
                                                                placeholder="Username"
                                                                style={customStyle}
                                                                value={values.username}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.username}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Label className="col-form-label col-3 text-lg-right text-left">Contact Number</Form.Label>
                                                        <Form.Group as={Col} md="9" controlId="validationUContact">
                                                            <Form.Control
                                                                required
                                                                min={0}
                                                                type="number"
                                                                name="contact_no"
                                                                onFocus={() => this.changeFocus()}
                                                                placeholder="Contact Number"
                                                                style={customStyle}
                                                                value={values.contact_no}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact_no}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.contact_no}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <Button type="submit" disabled={isSubmitting}
                                                                className="btn btn-info font-weight-bold text-center">
                                                                Save changes</Button>
                                                        </div>
                                                    </div>
                                                </Form>)}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={"tab-pane show px-7 active"} id="kt_user_edit_tab_2" role="tabpanel">

                                <div className="row">
                                    <div className="col card-body">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-7 my-2">
                                                <div className="row">
                                                    <label className="col-3"></label>
                                                    <div className="col-9">
                                                        <h6 className="text-dark font-weight-bold mb-5">Change Your Password</h6>
                                                    </div>
                                                </div>
                                                <Formik
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    validate={values => {
                                                        const errors = {};

                                                        if (!values.password) {
                                                            errors.password = "Please enter valid password";
                                                        }

                                                        if (!pwdValid.test(values.password)
                                                        ) {
                                                            errors.password = "Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                                        } else if (space.test(values.password)
                                                        ) {
                                                            errors.password = "Password can't contain space"
                                                        }

                                                        if (!values.confirmPwd) {
                                                            errors.confirmPwd = "Please enter valid confirm password";

                                                        }
                                                        if (
                                                            !pwdValid.test(values.confirmPwd)
                                                        ) {
                                                            errors.confirmPwd = "Confirm Password must contain at least 8 characters, one uppercase, one number and one special case character "
                                                        } else if (space.test(values.confirmPwd)
                                                        ) {
                                                            errors.password = "Confirm Password can't contain space"
                                                        } else if (values.password !== values.confirmPwd) {
                                                            errors.confirmPwd =
                                                                "Password and Confirm Password didn't match.";
                                                        }
                                                        return errors;
                                                    }}
                                                    onSubmit={(values, { setStatus, setSubmitting }) => {
                                                        this.handlePassword(values, setSubmitting);
                                                    }}
                                                    initialValues={{
                                                        email: user.email,
                                                        password: '',
                                                        confirmPwd: ''
                                                    }}>
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        values,
                                                        errors,
                                                        isSubmitting
                                                    }) => (
                                                        <Form noValidate={true}
                                                            onSubmit={handleSubmit}
                                                        >
                                                            <Form.Row>
                                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">Password</Form.Label>
                                                                <Form.Group as={Col} md="9" controlId="validationUpassword">
                                                                    <Form.Control
                                                                        required
                                                                        type="password"
                                                                        name="password"
                                                                        onFocus={() => this.changeFocus()}
                                                                        placeholder="Password"
                                                                        style={customStyle}
                                                                        value={values.password}
                                                                        onChange={handleChange}
                                                                        isInvalid={!!errors.password}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.password}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Label className="col-form-label col-3 text-lg-right text-left">Confirm Password</Form.Label>
                                                                <Form.Group as={Col} md="9" controlId="validationUconfirmPwd">
                                                                    <Form.Control
                                                                        required
                                                                        type="password"
                                                                        name="confirmPwd"
                                                                        onFocus={() => this.changeFocus()}
                                                                        placeholder="Confirm Password"
                                                                        style={customStyle}
                                                                        value={values.confirmPwd}
                                                                        onChange={handleChange}
                                                                        isInvalid={!!errors.confirmPwd}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{errors.confirmPwd}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <div className="row">
                                                                <div className="col-md-12 text-center">
                                                                    <Button type="submit" disabled={isSubmitting}
                                                                        className="btn btn-info font-weight-bold text-center">
                                                                        Save changes</Button>
                                                                </div>
                                                            </div>
                                                        </Form>)}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </PortletBody></Portlet>
            </div >
        );
    }
}

const mapStateToProps = ({ auth: { authToken, user } }) => ({
    authToken, user
});
const mapDispatchToProps = dispatch => {
    return {
        requestUser: topic => {
            dispatch(actions.requestUser(topic))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);