import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ACCEPT_INVITATION = "/accept-invitation";
export const REGISTER_USER = "/register-user";
export const GET_EMAIL_FROM_TOKEN = "/getEmailFromToken";

export const LOGIN_URL = "/admin/auth/login";
export const FORGOT_PASSWORD_URL = "/admin/auth/forgotPassword";
export const CHANGE_PASSWORD_URL = "/admin/auth/changePassword";

export const LOGOUT_URL = "/admin/auth/logout";

export const GET_USER = "/admin/";
export const EDIT_PROFILE = "/admin/editUser";
export const RESET_PWD = "/admin/updatePassword";

export const GET_USERS_LIST = "/admin/users";
export const ADD_USER = "/admin/users/add";
export const EDIT_USER_URL = "/admin/users/editUser";
export const DELETE_USER_URL = "/admin/users/deleteUser";
export const INVITE_USER = "/admin/users/invite-user";
export const RESEND_INVITATION = "/admin/users/resendInvitation";
export const CHANGE_USER_ROLE = "/admin/users/changeUserRole";
export const HANDLE_USER_STATUS = "/admin/users/handleUserStatus";
export const GET_IMPORTED = "/admin/users/importedUsers";
export const IMPORT_USER_FILE = "/admin/users/importUserFile";

export const GET_CATEGORY_LIST = "/admin/category";
export const ADD_CATEGORY = "/admin/category/addCategory";
export const ADD_CATEGORY_IMG = "/admin/category/addCategoryImg";
export const EDIT_CATEGORY = "/admin/category/editCategory";
export const EDIT_CATEGORY_IMG = "/admin/category/editCategoryImg";
export const DELETE_CATEGORY = "/admin/category/deleteCategory";
export const GET_ALL_CATEGORIES = "/admin/category/getAllCategoriesWithSub";
export const GET_ALL_SUBCATEGORIES = "/admin/category/getAllSubcategories";
export const IMPORT_EMPLOYEE_FILE = "/admin/category/importedEmployees";

export const GET_SUBCATEGORIES = "/admin/category/getSubcategories";
export const ADD_SUBCATEGORY = "/admin/category/addSubCategory";
export const EDIT_SUBCATEGORY = "/admin/category/editSubCategory";
export const DELETE_SUBCATEGORY = "/admin/category/deleteSubCategory";

export const GET_EXERCISE_LIST = "/admin/exercise";
export const ADD_EXERCISE = "/admin/exercise/addExercise";
export const EDIT_EXERCISE = "/admin/exercise/editExercise";
export const EDIT_EXERCISE_IMG = "/admin/exercise/editExerciseImg";
export const ADD_EXERCISE_IMG = "/admin/exercise/addExerciseImg";
export const DELETE_EXERCISE_IMG = "/admin/exercise/deleteExerciseImg";
export const DELETE_EXERCISE = "/admin/exercise/deleteExercise"
export const HANDLE_EXERCISE_STATUS = "/admin/exercise/handleExerciseStatus";
export const GET_ALL_EXERCISES = "/admin/exercise/getAllExercises";
export const GET_ASSIGNED_EXERCISES = "/admin/exercise/getAssignedExercises";
export const UNASSIGN_EXERCISES = "/admin/exercise/unAssignExercises";

export const GET_PRODUCT_LIST = "/admin/products";
export const ADD_PRODUCT = "/admin/products/addProduct";
export const ADD_PRODUCT_IMG = "/admin/products/addProductImg";
export const EDIT_PRODUCT = "/admin/products/editProduct";
export const EDIT_PRODUCT_IMG = "/admin/products/editProductImg";
export const DELETE_PRODUCT = "/admin/products/deleteProduct";

export const GET_REDEEM_LIST = "/admin/products/redeemHistory";

export const GET_USER_EXERCISE = "/admin/exercise/getUsersExercises";
export const ASSIGN_TO_USER = "/admin/exercise/assignToUser";

export const GET_EMAILS = "/admin/users/officeEmails";
export const UPDATE_EMAILS = "/admin/users/updateOfficeEmails";
export const ADD_EMAILS = "/admin/users/addOfficeEmails";

export const UPLOAD_FILE_CHUNKS = "/admin/exercise/upload";
export const UPLOAD_PDFFILE_CHUNKS = "/admin/exercise/uploadPdf";

export const GET_STAFF_LIST = "/admin/staff";
export const UPDATE_STAFF_STATUS = "/admin/staff/updateUsersStatus";
export const ADD_STAFF_USER = "/admin/staff/provider";
export const UPDATE_STAFF_USER = "/admin/staff/provider";
export const DELETE_STAFF_USER = "/admin/staff/provider";

export const API = axios.create({
  baseURL: API_BASE_URL
});

export function acceptInvitation(values, email, password) {
  let data = JSON.stringify({
    token: values.token,
    email: email,
    password: password
  });
  return API.post(ACCEPT_INVITATION, data, { headers: { "Content-Type": "application/json" } });
}

export function registerUser(data) {
  return API.post(REGISTER_USER, data, { headers: { "Content-Type": "application/json" } });
}

export function resendInvitation(token, data) {
  return API.post(RESEND_INVITATION, data, { headers: { "Authorization": token } });
}

export function changeUserRole(token, data) {
  return API.post(CHANGE_USER_ROLE, data, { headers: { "Authorization": token } });
}

export function login(username, password) {
  let data = JSON.stringify({
    email: username,
    password: password
  });
  return API.post(LOGIN_URL, data, { headers: { "Content-Type": "application/json" } });
}

export function forgotPassword(email) {
  let data = JSON.stringify({
    email: email
  });
  return API.post(FORGOT_PASSWORD_URL, data, { headers: { "Content-Type": "application/json" } });
}

export function changePassword(values, password) {

  let data = JSON.stringify({
    email: values.email,
    token: values.resetToken,
    password: password
  });
  return API.post(CHANGE_PASSWORD_URL, data, { headers: { "Content-Type": "application/json" } });
}

export async function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  return API.get(GET_USER, { headers: { "Authorization": token } });
}

// export async function updatePassword(data, authToken) {
//   return API.post(UPDATE_PASSWORD, data, { headers: { 'Authorization': authToken } });
// }

export async function editUser(authToken, data) {
  return API.post(EDIT_USER_URL, data, { headers: { "Authorization": authToken } });
}

export async function deleteUser(authToken, data) {
  return API.post(DELETE_USER_URL, data, { headers: { "Authorization": authToken } });
}

export async function getUsersList(token, searchData, limit, sortBy, sortOrder, page, startDate, endDate, status, role) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var sDate = (startDate !== undefined && startDate !== null) ? startDate : "";
  var eDate = (endDate !== undefined && endDate !== null) ? endDate : "";
  var sValue = (status !== undefined && status !== null) ? status : "";
  return API.get(GET_USERS_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&startDate=" + sDate +
    "&endDate=" + eDate + "&status=" + sValue + "&role=" + role, { headers: { "Authorization": token } });
}

export function getEmailFromToken(data) {
  return API.get(GET_EMAIL_FROM_TOKEN + '?token=' + data, { headers: { "Content-Type": "application/json" } })
}

export async function addUser(token, data) {
  return API.post(ADD_USER, data, { headers: { "Authorization": token } });
}

export async function editProfile(token, data) {
  return API.post(EDIT_PROFILE, data, { headers: { "Authorization": token } });
}

export async function resetPwdAdmin(token, data) {
  return API.post(RESET_PWD, data, { headers: { "Authorization": token } });
}

export async function inviteUser(token, data) {
  return API.post(INVITE_USER, data, { headers: { "Authorization": token } });
}

export async function handleUserStatus(token, data) {
  return API.post(HANDLE_USER_STATUS, data, { headers: { "Authorization": token } });
}

export async function getCategoryList(token, searchData, limit, sortBy, sortOrder, page, typeF) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var type = (typeF !== undefined && typeF !== null) ? typeF : "";
  // var sDate = (startDate !== undefined && startDate !== null) ? startDate : "";
  // var eDate = (endDate !== undefined && endDate !== null) ? endDate : "";
  // var sValue = (status !== undefined && status !== null) ? status : "";
  return API.get(GET_CATEGORY_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + '&type=' + type, { headers: { "Authorization": token } });
}

export async function getSubcategories(token, searchData, limit, sortBy, sortOrder, page, categoryFilter) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  // var sDate = (startDate !== undefined && startDate !== null) ? startDate : "";
  // var eDate = (endDate !== undefined && endDate !== null) ? endDate : "";
  var cValue = (categoryFilter !== undefined && categoryFilter !== null) ? categoryFilter : "";
  return API.get(GET_SUBCATEGORIES + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&categoryFilter=" + cValue, { headers: { "Authorization": token } });
}

export async function addCategory(authToken, data) {
  return API.post(ADD_CATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function editCategory(authToken, data) {
  return API.post(EDIT_CATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function deleteCategory(authToken, data) {
  return API.post(DELETE_CATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function addSubCategory(authToken, data) {
  return API.post(ADD_SUBCATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function editSubCategory(authToken, data) {
  return API.post(EDIT_SUBCATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function deleteSubCategory(authToken, data) {
  return API.post(DELETE_SUBCATEGORY, data, { headers: { "Authorization": authToken } });
}

export async function getExerciseList(token, searchData, limit, sortBy, sortOrder, page, startDate, endDate, status,
  categoryFilter, subcategoryFilter) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var sDate = (startDate !== undefined && startDate !== null) ? startDate : "";
  var eDate = (endDate !== undefined && endDate !== null) ? endDate : "";
  var sValue = (status !== undefined && status !== null) ? status : "";
  var cValue = (categoryFilter !== undefined && categoryFilter !== null) ? categoryFilter : "";
  var subF = (subcategoryFilter !== undefined && subcategoryFilter !== null) ? subcategoryFilter : "";
  return API.get(GET_EXERCISE_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&startDate=" + sDate +
    "&endDate=" + eDate + "&status=" + sValue + "&categoryFilter=" + cValue + "&subcategoryFilter=" + subF, { headers: { "Authorization": token } });
}

export async function getAllCategoriesWithSub(authToken) {
  return API.get(GET_ALL_CATEGORIES, { headers: { "Authorization": authToken } });
}

export async function getAllSubcategories(authToken) {
  return API.get(GET_ALL_SUBCATEGORIES, { headers: { "Authorization": authToken } });
}

export async function addExercise(authToken, data) {
  return API.post(ADD_EXERCISE, data, {
    headers: {
      "Authorization": authToken
    }
  });
}

export async function addExerciseImg(authToken, data, postdata) {
  return API.post(ADD_EXERCISE_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': "multipart/form-data"
    }
  });
}

export async function deleteExercise(authToken, data) {
  return API.post(DELETE_EXERCISE, data, { headers: { "Authorization": authToken } });
}

export async function handleExerciseStatus(token, data) {
  return API.post(HANDLE_EXERCISE_STATUS, data, { headers: { "Authorization": token } });
}

export async function editExercise(token, data) {
  return API.post(EDIT_EXERCISE, data, { headers: { "Authorization": token } });
}

export async function deleteExerciseImg(token, data) {
  return API.post(DELETE_EXERCISE_IMG, data, { headers: { "Authorization": token } });
}

export async function editExerciseImg(authToken, data, postdata) {
  return API.post(EDIT_EXERCISE_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': "multipart/form-data"
    }
  });
}

export async function addCategoryImg(authToken, data, postdata) {
  return API.post(ADD_CATEGORY_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function editCategoryImg(authToken, data, postdata) {
  return API.post(EDIT_CATEGORY_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function getProductList(token, searchData, limit, sortBy, sortOrder, page, status) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var sValue = (status !== undefined && status !== null) ? status : "";
  return API.get(GET_PRODUCT_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + '&status=' + sValue, { headers: { "Authorization": token } });
}

export async function addProductImg(authToken, data, postdata) {
  return API.post(ADD_PRODUCT_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function addProduct(authToken, data) {
  return API.post(ADD_PRODUCT, data, { headers: { "Authorization": authToken } });
}

export async function editProduct(authToken, data) {
  return API.post(EDIT_PRODUCT, data, { headers: { "Authorization": authToken } });
}

export async function deleteProduct(authToken, data) {
  return API.post(DELETE_PRODUCT, data, { headers: { "Authorization": authToken } });
}

export async function editProductImg(authToken, data, postdata) {
  return API.post(EDIT_PRODUCT_IMG + '?postdata=' + postdata, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function getRedeemList(token, searchData, limit, sortBy, sortOrder, page, role) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  return API.get(GET_REDEEM_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&role=" + role, { headers: { "Authorization": token } });
}

export async function getUsersExercises(token, searchData, limit, sortBy, sortOrder, page, startDate, endDate, role) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var sDate = (startDate !== undefined && startDate !== null) ? startDate : "";
  var eDate = (endDate !== undefined && endDate !== null) ? endDate : "";
  return API.get(GET_USER_EXERCISE + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&startDate=" + sDate +
    "&endDate=" + eDate + "&role=" + role, { headers: { "Authorization": token } });
}

export async function getAllExercises(authToken) {
  return API.get(GET_ALL_EXERCISES, { headers: { "Authorization": authToken } });
}

export async function getImported(authToken) {
  return API.get(GET_IMPORTED, { headers: { "Authorization": authToken } });
}

export async function assignToUser(authToken, data) {
  return API.post(ASSIGN_TO_USER, data, { headers: { "Authorization": authToken } });
}

export async function importUserFile(authToken, data) {
  return API.post(IMPORT_USER_FILE, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function uploadLargeVideoInChunks(authToken, postdata, data) {
  return API.post(UPLOAD_FILE_CHUNKS + '?' + postdata, data, {
    headers: {
      'Authorization': authToken,
      'Content-Type': 'application/octet-stream'
    }
  });
}

// export async function uploadPdfInChunks(authToken, postdata, data) {
//   return API.post(UPLOAD_PDFFILE_CHUNKS + '?' + postdata, data, {
//     headers: {
//       'Authorization': authToken,
//       'Content-Type': 'application/octet-stream'
//     }
//   });
// }

export async function importEmployeeFile(authToken, data) {
  return API.post(IMPORT_EMPLOYEE_FILE, data, {
    headers: {
      "Authorization": authToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function getOfficeEmails(token, searchData, limit, sortBy, sortOrder, page, status, role) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  var sValue = (status !== undefined && status !== null) ? status : "";
  return API.get(GET_EMAILS + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&status=" + sValue, { headers: { "Authorization": token } });
}

export async function updateOfficeEmails(authToken, data) {
  return API.post(UPDATE_EMAILS, data, { headers: { "Authorization": authToken } });
}

export async function addOfficeEmails(authToken, data) {
  return API.post(ADD_EMAILS, data, { headers: { "Authorization": authToken } });
}

export async function getAssignedExercise(authToken, searchData, limit, sortBy, sortOrder, page, user_id) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  return API.get(GET_ASSIGNED_EXERCISES + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page + "&user_id=" + user_id, { headers: { "Authorization": authToken } });
}

export async function unAssignUserExercise(authToken, data) {
  return API.post(UNASSIGN_EXERCISES, data, { headers: { "Authorization": authToken } });
}

export async function getStaffList(token, searchData, limit, sortBy, sortOrder, page) {
  var searchValue = (searchData !== undefined && searchData !== null) ? searchData : "";
  return API.get(GET_STAFF_LIST + '?search=' + searchValue + '&limit=' + limit +
    '&sortBy=' + sortBy + '&sortOrder=' + sortOrder + "&page=" + page, { headers: { "Authorization": token } });
}

export async function handleProviderstatus(token, data) {
  return API.post(UPDATE_STAFF_STATUS, data, { headers: { "Authorization": token } });
}


export async function addStaffUser(token, data) {
  return API.post(ADD_STAFF_USER, data, { headers: { "Authorization": token } });
}

export async function updateStaffUser(token, data) {
  return API.put(UPDATE_STAFF_USER, data, { headers: { "Authorization": token } });
}

export async function deleteStaffUser(token, data) {
  return API.delete(DELETE_STAFF_USER, { headers: { "Authorization": token }, data });
}