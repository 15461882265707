/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import { getUsersList, addUser, editUser, deleteUser, inviteUser, importUserFile, handleUserStatus, resendInvitation } from "../../crud/auth.crud";
import moment from 'moment-timezone';
import { DropzoneArea } from 'material-ui-dropzone';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { InsertDriveFile } from '@material-ui/icons';
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router-dom";

class Employee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            userCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 25,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentUser: null,
            validated: false,
            disabledBtn: false
        };
        this.inputRef = createRef();
    }
    

    componentDidMount = async () => {
        this.getUsersList();
    }

    getUsersList = async (searchData) => {

        const { authToken } = this.props;

        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        var status = (this.state.status === 0 || this.state.status === 1) ? this.state.status : null;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        var role = 2;
        await getUsersList(authToken, search, limitV, sortByV,
            sortOrderV, activePage, startDate, endDate, status, role).then(result => {
                this.setState({
                    userData: result.data.payload ? result.data.payload.data.rows : [],
                    userCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    userData: [],
                    userCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getUsersList(event.target.value);

        // }
        if (event.target.value.length === 0) {
            this.getUsersList();
        }
    };

    handleStatus = (value) => {
        this.setState({ status: value });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleModal = (value, currentUser) => {
        this.setState({ modalType: value, show: true, currentUser });
    }

    handleClose = () => {
        this.setState({ show: false, disabledBtn: false });
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleShow = (e) => {
        this.setState({
            show: true,
        });
    }

    handleAssigendExercise = (id) => {
        this.props.history.push({
            pathname: '/admin/assignedExercise',
            state: { user_id: id }
        })
    }

    handleSwitchChange = async (value, item) => {
        const { authToken } = this.props;
        var data = {
            id: item.id,
            status: (value === false) ? 0 : 1
        }
        await handleUserStatus(authToken, data).then(result => {
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }

    handleEditEmployeeSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            id: this.state.currentUser.id,
            fullname: values.fullname.trim(),
            email: values.email.trim(),
            username: values.username.trim(),
            contact_no: values.contact_no,
            role: 2
        }    
        await editUser(authToken, data).then(result => {
            this.handleClose();
            this.setState({ loading: false });
            setSubmitting(true);
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            this.setState({ loading: false });
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleAddEmployeeSubmit = async (values, setSubmitting) => {
        const { authToken } = this.props;
        var data = {
            fullname: values.fullname.trim(),
            email: values.email.trim(),
            username: values.username.trim(),
            contact_no: values.contact_no,
            role: 2
        }

        this.setState({ loading: true });
        setSubmitting(true);
        await addUser(authToken, data).then(result => {
            setSubmitting(false);
            this.handleClose();
            this.setState({ loading: false });
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            setSubmitting(false);
            console.log(err.response, err.message);
            this.handleClose();
            this.setState({ loading: false });
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getUsersList();
        }, 500);
    }

    handleDelete = async (event) => {
        this.setState({ disabledBtn: true });
        const { authToken } = this.props;
        var data = {
            id: this.state.currentUser.id
        }
        await deleteUser(authToken, data).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            console.log(err.response, err.message);
            this.handleClose();
            var msg = err.response ? err.response.data.message : err.message;
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            this.getUsersList();
        }, 500);

    }

    renderModalBody = () => {
        const { isFocus, modalType, currentUser, disabledBtn,loading } = this.state;
        const customStyle = isFocus ? { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', borderColor: "#E3E3E3", color: "black" }
            : { borderRadius: 0, borderWidth: 2, backgroundColor: 'transparent', color: 'black' };

        const isEdit = modalType === "Edit" ? true : false;
        const roleType = 2;
        if (modalType === "Add" || modalType === "Edit") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>{modalType} Employee</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};

                            if (!values.email) {
                                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                errors.email = "Please provide email address"
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = "Please provide valid email address"
                            }

                            if (values.fullname.trim().length <= 0) {
                                errors.fullname = "Please provide valid fullname"
                            }
                            if (values.username.trim().length <= 0) {
                                errors.username = "Please provide valid username"
                            }
                            if (values.contact_no.length <= 0) {
                                errors.contact_no = "Please provide valid contact number"
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            if (isEdit) {
                                this.handleEditEmployeeSubmit(values, setSubmitting);
                            } else {
                                this.handleAddEmployeeSubmit(values, setSubmitting);
                            }

                        }}
                        initialValues={{
                            role: roleType,
                            fullname: isEdit ? currentUser && currentUser.fullname : '',
                            email: isEdit ? currentUser && currentUser.email : '',
                            username: isEdit ? currentUser && (currentUser.username != null ? currentUser.username : '') : '',
                            contact_no: isEdit ? currentUser && currentUser.contact_no : '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isSubmitting
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">

                                    <Form.Control type="hidden" name="role" value={values.roletype} />
                                    <Form.Group as={Col} md="12" controlId="validationFullname">

                                        <Form.Label style={{ fontWeight: 600 }}>Full name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="fullname"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Full name"
                                            style={customStyle}
                                            value={values.fullname}
                                            onChange={handleChange}
                                            isInvalid={!!errors.fullname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.fullname}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationUsername">
                                        <Form.Label style={{ fontWeight: 600 }}>Username</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="username"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Username"
                                            style={customStyle}
                                            value={values.username}
                                            onChange={handleChange}
                                            isInvalid={!!errors.username}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
                                        <Form.Control
                                            required
                                            readOnly={isEdit}
                                            type="text"
                                            name="email"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Email"
                                            style={customStyle}
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationContact">
                                        <Form.Label style={{ fontWeight: 600 }}>Contact Number</Form.Label>
                                        <Form.Control
                                            required
                                            min={0}
                                            type="number"
                                            name="contact_no"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Contact Number"
                                            style={customStyle}
                                            value={values.contact_no}
                                            onChange={handleChange}
                                            isInvalid={!!errors.contact_no}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.contact_no}</Form.Control.Feedback>
                                    </Form.Group>


                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>

                                        {loading && <i style={{ margin: '0px 5px' }}
                                                className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                                            {isEdit ? "Update" : "Add"}
                                    </Button>
                                </Modal.Footer>
                            </Form>)}
                    </Formik>
                </div>
            );
        } else if (modalType === "Invite") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Invite User</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validate={values => {
                            const errors = {};

                            if (!values.email) {
                                errors.email = "Please provide email address"
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = "Please provide valid email address"
                            }

                            return errors;
                        }}
                        onSubmit={async (values, { setStatus, setSubmitting }) => {
                            const { authToken } = this.props;
                            await inviteUser(authToken, values).then(result => {
                                this.handleClose();
                                setSubmitting(false);
                                toast.success(result.data.message, {
                                    className: "green-css"
                                });
                            }).catch(err => {
                                console.log('erres', err.response)
                                this.handleClose();
                                var msg = err.response && err.response.data.message;
                                setSubmitting(false);
                                toast.error(msg, {
                                    className: "red-css"
                                });
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 4000);
                        }}
                        initialValues={{
                            email: ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isSubmitting
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label style={{ fontWeight: 600 }}>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="email"
                                            onFocus={() => this.changeFocus()}
                                            placeholder="Email"
                                            style={customStyle}
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        Send
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}</Formik>

                </div>
            );
        } else if (modalType === "Delete") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Delete Employee</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to delete
                                    this user with <b>{currentUser && currentUser.email}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.handleDelete(e)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        } else if (modalType === "Resend") {
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Resend Invitation</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Form noValidate>
                        <Modal.Body>
                            <Form.Group as={Col} md="12" className={"text-center"}>
                                <Form.Label style={{ fontWeight: 400 }}>Are you sure you want to resend invitation
                                    to this user with <b>{currentUser && currentUser.email}</b> ?</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <hr className="line-style" />
                        <Modal.Footer>
                            <Button className="ml-auto mr-3" variant="info" disabled={disabledBtn}
                                onClick={(e) => this.resendInvitation(e, currentUser)}>
                                Resend Invitation
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            );
        } else if (modalType === "Import") {
            const handlePreviewIcon = (fileObject, classes) => {
                const { type } = fileObject.file

                const iconProps = {
                    className: classes.image,
                }

                return <InsertDriveFile {...iconProps} />
            }
            return (
                <div>
                    <Modal.Header closeButton style={{ padding: '0px 0px 0px 20px' }}>
                        <Modal.Title style={{ fontWeight: 700 }}>Import User</Modal.Title>
                    </Modal.Header>
                    <hr style={{ borderWidth: 2 }} />
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        onSubmit={async (values, { setStatus, setSubmitting }) => {
                            const { authToken } = this.props;
                            if (values.importFile) {
                                const data = new FormData();
                                data.append('importFile', values.importFile)

                                importUserFile(authToken, data).then(result => {
                                    this.handleClose();
                                    toast.success(result.data.message, {
                                        className: "green-css"
                                    });
                                }).catch(errors => {
                                    setSubmitting(false);
                                    this.handleClose();
                                    var msg = errors.response ? errors.response.data.message : errors.message;
                                    toast.error(msg, {
                                        className: "red-css"
                                    });
                                })
                            } else {
                                setStatus(
                                    "Please provide file."
                                );
                                setSubmitting(false);
                            }
                        }}
                        initialValues={{
                            importFile: null
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            status,
                            isSubmitting,
                            ...props
                        }) => (
                            <Form noValidate={true}
                                onSubmit={handleSubmit}
                            >
                                <Modal.Body className="pt-0">
                                    <Form.Row>
                                        <Form.Label className="col-md-12" style={{ fontWeight: 600 }}>Attach File</Form.Label>
                                    </Form.Row>
                                    <Form.Row>
                                        <DropzoneArea
                                            dropzoneText={"Drag and drop a file here or click"}
                                            dropzoneClass={status ? "dropzone dropzone-default min-drop p-2 custom-border" : "dropzone dropzone-default min-drop p-2"}
                                            dropzoneParagraphClass={status ? "dropzone-msg-title custom-border" : "dropzone-msg-title"}
                                            acceptedFiles={[".csv, text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                            filesLimit={1}
                                            getPreviewIcon={handlePreviewIcon}
                                            showAlerts={['error']}
                                            onChange={(files) => props.setFieldValue("importFile", files[0])}
                                        />
                                        {status && (<div className="invalid-msg">{status}</div>)}
                                    </Form.Row>
                                </Modal.Body>
                                <hr className="line-style" />
                                <Modal.Footer>
                                    {/* <Button type="submit" className="ml-auto mr-3" variant="info" disabled={isSubmitting}>
                                        Import Users
                                    </Button> */}
                                </Modal.Footer>
                            </Form>
                        )}</Formik>

                </div >
            );
        }
    }

    resendInvitation = async (e, item) => {
        const { authToken } = this.props;
        var postdata = {
            email: item.email,
            inviteToken: item.inviteToken
        }
        await resendInvitation(authToken, postdata).then(result => {
            this.handleClose();
            toast.success(result.data.message, {
                className: "green-css"
            });
        }).catch(err => {
            var msg = err.response && err.response.data.message;
            this.handleClose();
            toast.error(msg, {
                className: "red-css"
            });
        });
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }

    render() {
        const { userData, userCount, activePage, limit, searchValue, loading, isFocus, startDate, endDate } = this.state;
        const customStyle = isFocus ? styleSearch
            : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon} />

                <div className="d-flex flex-row-reverse mr-5 mt-3">
                    {/* <button
                        disabled={loading}
                        className='btn btn-warning btn-elevate kt-login__btn-warning ml-3'
                        onClick={(e) => this.handleModal("Import")}
                    >
                        {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                        Import User
                    </button>
                    <button
                        disabled={loading}
                        className='btn btn-info btn-elevate kt-login__btn-info ml-3'
                        onClick={(e) => this.handleModal("Invite")}
                    >
                        {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />}
                        Invite User
                    </button> */}
                    <button
                        //disabled={loading}
                        className='btn btn-warning btn-elevate kt-login__btn-warning'
                        onClick={(e) => this.handleModal("Add")}
                    >
                        {/* {loading && <i style={{ margin: '0px 5px' }}
                            className={'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light'} />} */}
                        Add Employee
                    </button>


                </div>
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                            <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                    Status</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleStatus(1)}>Active</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(0)}>Inactive</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleStatus(2)}>All</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                        <div className="row"><div
                                            className="input-group align-self-center col-md-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                    <i className="flaticon2-search-1" />
                                                </span>
                                            </div>

                                            <input
                                                type="text"
                                                ref={this.inputRef}
                                                placeholder="Search..."
                                                value={searchValue}
                                                onFocus={() => this.changeFocus()}
                                                style={customStyle}
                                                onChange={this.handleSearchChange}
                                                className="form-control kt-quick-search__input"
                                            />
                                            {(searchValue) && (
                                                <div className="input-group-append" onClick={this.clear}>
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="la la-close kt-quick-search__close"
                                                            style={{ display: "flex" }} />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                            <div className="col-md-3 mb-5" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={startDate}
                                                        autoComplete="off"
                                                        onChange={(value) => this.handleChange('startDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-3 mb-5 mr-4" >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        name="endDate"
                                                        label="End Date"
                                                        format="MM/dd/yyyy"
                                                        minDate={startDate}
                                                        autoComplete="off"
                                                        maxDate={new Date('2050/12/31')}
                                                        value={endDate}
                                                        onChange={(value) => this.handleChange('endDate', value)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                {/* <th><b>id</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                </th> */}
                                                <th><b>Username</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('username', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('username', 'DESC')} />
                                                </th>
                                                <th><b>Email</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('email', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('email', 'DESC')} />
                                                </th>
                                                <th><b>Fullname</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('fullname', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('fullname', 'DESC')} />
                                                </th>
                                                <th><b>Contact Number</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('contact_no', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('contact_no', 'DESC')} />
                                                </th>
                                                <th><b>Earned Points</b></th>
                                                <th><b>Status</b></th>
                                                <th><b>Date</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                </th>
                                                <th><b>Assigned Exercises</b>
                                                </th>
                                                <th><b>Action</b>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {userData.length > 0 ?
                                                userData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.username ? item.username : "-"}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.fullname ? item.fullname : "-"}</td>
                                                        <td>{item.contact_no ? item.contact_no : "-"}</td>
                                                        <td>{item.totalPoints ? item.totalPoints : 0}</td>
                                                        <td><Switch
                                                            onChange={(e) =>
                                                                this.handleSwitchChange(e, item)}
                                                            checked={item.status === 0 ? false : true}
                                                        /></td>
                                                        <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td>
                                                        <td>
                                                            {item.eUserExercise > 0 ?
                                                                <OverlayTrigger
                                                                    placement="right"
                                                                    overlay={<Tooltip id="documentations-tooltip">View Exercises</Tooltip>}
                                                                    value
                                                                >
                                                                    <a className=""
                                                                    style={{ color: "#2CA8FF" }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleAssigendExercise(item.id);
                                                                        }}
                                                                    >
                                                                        {item.eUserExercise}</a>
                                                                </OverlayTrigger>
                                                                : "0"
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">
                                                                    {(item.status === 0) ?
                                                                        "User is inactive so cannot edit userdata" :
                                                                        "Edit"}</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-edit pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (item.status === 1) {
                                                                            this.handleModal("Edit", item);
                                                                        }
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip id="documentations-tooltip">Delete</Tooltip>}>
                                                                <a className="kt-menu__link-icon flaticon2-rubbish-bin-delete-button pr-4"
                                                                    style={{ color: "#2CA8FF", fontSize: '1.3rem' }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleModal("Delete", item)
                                                                    }}>{""}</a>
                                                            </OverlayTrigger>

                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>

                {(userCount > limit) &&
                    <div className="d-flex flex-row-reverse">
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={userCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
                <Modal centered size="md" show={this.state.show} onHide={() => {
                    this.handleClose();
                }} style={{ opacity: 1 }}>
                    {this.renderModalBody()}
                </Modal>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default withRouter(connect(mapStateToProps)(Employee));