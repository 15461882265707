/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";
import { Formik } from "formik";
import {
    Button,
    Dropdown,
    Modal,
    OverlayTrigger,
    Tooltip,
    Form,
    Col
} from "react-bootstrap";
import {
    getRedeemList, addProduct, editProduct, deleteProduct, addProductImg, editProductImg
} from "../../crud/auth.crud";
import Switch from "react-switch";
import { DropzoneArea } from 'material-ui-dropzone';
import { PictureAsPdf, OndemandVideo, Photo } from '@material-ui/icons';
import { TitleComponent } from "../FormComponents/TitleComponent";
import Pagination from 'react-js-pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';

class RedeemHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redeemData: [],
            redeemCount: 0,
            startDate: null,
            endDate: null,
            showAlert: false,
            loading: false,
            searchValue: "",
            limit: 10,
            status: "",
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            activePage: 1,
            isFocus: false,
            show: false,
            modalType: "",
            currentProduct: null,
            validated: false,
            disabledBtn: false,
            role:0
        };
        this.inputRef = createRef();
    }


    componentDidMount = async () => {
        this.getRedeemList();
    }

    getRedeemList = async (searchData) => {
        const { authToken } = this.props;
        var limitV = this.state.limit;
        var sortByV = this.state.sortBy;
        var sortOrderV = this.state.sortOrder;
        var activePage = this.state.activePage;
        var search = (this.state.searchValue !== undefined) ? this.state.searchValue : null;
        var role = this.state.role;
        await getRedeemList(authToken, search, limitV, sortByV,
            sortOrderV, activePage,role).then(result => {
                this.setState({
                    redeemData: result.data.payload ? result.data.payload.data.rows : [],
                    redeemCount: result.data.payload && result.data.payload.data.count
                });
            }).catch(err => {
                console.log(err.response, err.message)
                this.setState({
                    redeemData: [],
                    redeemCount: 0
                });
                if (err.response && (err.response.data.message === "jwt expired") || err.message === "Network Error") {
                    window.location.href = "/admin/logout";
                }
            });
    }

    clear = () => {
        this.setState({ searchValue: "" });
        setTimeout(() => {
            this.getRedeemList();
        }, 500);
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
        // if (event.target.value.length > 0) {
        //     this.getRedeemList(event.target.value);
        // }
        if (event.target.value.length === 0) {
            this.getRedeemList();
        }
    };

    handleSorting = (sortBy, sortOrder) => {
        this.setState({
            sortBy: sortBy,
            sortOrder: sortOrder,
        });
        setTimeout(() => {
            this.getRedeemList();
        }, 500);
    }

    handleSelect = (number) => {
        this.setState({ activePage: number });
        setTimeout(() => {
            this.getRedeemList();
        }, 500);
    }

    changeFocus = () => {
        this.setState({ isFocus: true });
    }

    handleSubmit = () => {
        this.setState({ activePage: 1 });
        setTimeout(() => {
            this.getRedeemList();
        }, 500);
    }

    handleReset = () => {
        window.location.reload();
    }
    handleUserRole= (value) => {
        console.log("value",value)
        this.setState({ role: value, });
        setTimeout(() => {
            this.getRedeemList();
        }, 500);
    }

    render() {
        const { redeemData, redeemCount, activePage,
            limit, searchValue, loading, isFocus, role } = this.state;
        const customStyle = isFocus ? styleSearch : { borderWidth: 2 };

        return (
            <div>
                <TitleComponent title={this.props.title} icon={this.props.icon}  />
                <ToastContainer />
                <Portlet className="m-5">
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="kt-section">
                                    <form className="kt-quick-search__form" onSubmit={(e) => { e.preventDefault() }}>
                                        <div className="row">
                                        <Dropdown className="col-md-auto" drop="down">
                                                <Dropdown.Toggle variant="info" id="dropdown-basic" >
                                                    Filter By Role</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(1)}>Users</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(2)}>Employees</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.handleUserRole(0)}>All</Dropdown.Item>
                                                    </Dropdown.Menu>
                                            </Dropdown>
                                            <div
                                                className="input-group align-self-center col-md-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                        <i className="flaticon2-search-1" />
                                                    </span>
                                                </div>

                                                <input
                                                    type="text"
                                                    ref={this.inputRef}
                                                    placeholder="Search..."
                                                    value={searchValue}
                                                    onFocus={() => this.changeFocus()}
                                                    style={customStyle}
                                                    onChange={this.handleSearchChange}
                                                    className="form-control kt-quick-search__input"
                                                />
                                                {(searchValue) && (
                                                    <div className="input-group-append" onClick={this.clear}>
                                                        <span className="input-group-text" style={{ borderWidth: 2 }}>
                                                            <i className="la la-close kt-quick-search__close"
                                                                style={{ display: "flex" }} />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mr-3 mt-1 ml-auto">
                                                <button type="button" className='btn btn-primary btn-elevate kt-login__btn-primary'
                                                    onClick={this.handleSubmit}>
                                                    Search
                                                </button>
                                            </div>
                                            <div className="mr-3 mt-1">
                                                <button type="button" className='btn btn-info btn-elevate kt-login__btn-info'
                                                    onClick={this.handleReset}>
                                                    Reset</button>
                                            </div>

                                        </div>
                                    </form>
                                    <Table striped responsive bordered hover className="table-list-header">
                                        <thead>
                                            <tr>
                                                {/* <th><b>id</b>
                                                <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('id', 'DESC')} />
                                                </th> */}
                                                <th><b>Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('name', 'DESC')} />
                                                </th>
                                                <th><b>Product Name</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('product_name', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('product_name', 'DESC')} />
                                                </th>
                                                <th><b>Points</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('points', 'DESC')} /></th>
                                                <th><b>Date</b>
                                                    <i className='flaticon2-up ml-2 ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'ASC')} />
                                                    <i className='flaticon2-down ctriggerclick arrow-icons' style={{ fontSize: 10 }} onClick={() => this.handleSorting('createdAt', 'DESC')} />
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {redeemData.length > 0 ?
                                                redeemData.map((item, index) =>
                                                    <tr key={item.id}>
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.User ? item.User.fullname : "-"}<br/><div style={{fontSize: 12}}>{item.User ? item.User.email : "-"}</div></td>
                                                        <td>{item.Product ? item.Product.name : "-"}</td>
                                                        <td>{item.points ? item.points : "-"}</td>
                                                        <td>{moment(item.createdAt).format('MM-DD-YYYY hh:mm A')}</td>

                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="12" className="text-center">
                                                        No records found!
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                        </div>

                    </PortletBody>
                </Portlet>

                <div className="d-flex flex-row-reverse">
                    {(redeemCount > limit) &&
                        <Pagination
                            bsSize={'medium'}
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={redeemCount}
                            pageRangeDisplayed={limit}
                            onChange={this.handleSelect}
                            itemClass="page-item"
                            linkClass="page-link"
                        />}
                </div>
            </div>
        );
    }
}

const styleSearch = {
    borderColor: "#E3E3E3", borderWidth: 2, borderLeftWidth: 0, borderRightWidth: 0
}

const mapStateToProps = ({ auth: { authToken } }) => ({
    authToken
});

export default connect(mapStateToProps)(RedeemHistory);